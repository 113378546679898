<template>

  <Dialog
      :style="{width: '43vw'}"
      v-model:visible="computedVisible"
      :modal="true"
      :closable="true">

    <div id="overlay" v-if="overlay"></div>

    <template #header>
      <h3>Banca {{ banking && banking.tbanca }}</h3>
    </template>

    <TabView :activeIndex="activeIndex" scrollable>

      <!--      Tab 1 -->
      <TabPanel>
        <template #header>
          <i class="pi pi-home icon"></i>
          <span class="tab-title">&nbsp;&nbsp;Detalles</span>
        </template>
        <Tab1
            @close="closeDialog"
            :banking="banking"
        />
      </TabPanel>

      <!--        Tab 2 -->
      <TabPanel v-if="banking.bred_propia">
        <template #header>
          <i class="pi pi-percentage icon"></i>
          <span class="tab-title">&nbsp;&nbsp;Porcentajes</span>
        </template>
        <Tab2
            @close="closeDialog"
            :banking="banking"
        />
      </TabPanel>

      <!--      Tab 3 -->
      <TabPanel v-if="banking.bred_propia">
        <template #header>
          <i class="pi pi-exclamation-triangle icon"></i>
          <span class="tab-title">&nbsp;&nbsp;Cupos</span>
        </template>
        <Tab3
            @close="closeDialog"
            :banking="banking"
        />
      </TabPanel>

      <!--      Tab 4 -->
      <TabPanel v-if="banking.bred_propia">
        <template #header>
          <i class="pi pi-directions icon"></i>
          <span class="tab-title">&nbsp;&nbsp;Límites</span>
        </template>
        <Tab4
            @close="closeDialog"
            :banking="banking"
        />
      </TabPanel>

      <!--      Tab 5 -->
      <TabPanel v-if="banking.bred_propia">
        <template #header>
          <i class="pi pi-directions icon"></i>
          <span class="tab-title">&nbsp;&nbsp;Misceláneos</span>
        </template>
        <Tab5
            :banking="banking"
        />
      </TabPanel>

      <!--      Tab 6 -->
      <TabPanel v-if="banking.bred_propia">
        <template #header>
          <i class="pi pi-directions icon"></i>
          <span class="tab-title">&nbsp;&nbsp;Distribuidores</span>
        </template>
        <Tab6
            :banking="banking"
        />
      </TabPanel>

    </TabView>


    <template #footer>
      <!--      <Button label="No" icon="pi pi-times" class="p-button-text"/>-->
      <!--      <Button label="Yes" icon="pi pi-check" autofocus/>-->
    </template>
  </Dialog>

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import Tab1 from "@/components/Settings/Bankings/Dialog/Tab1";
import Tab2 from "@/components/Settings/Bankings/Dialog/Tab2";
import Tab3 from "@/components/Settings/Bankings/Dialog/Tab3";
import Tab4 from "@/components/Settings/Bankings/Dialog/Tab4";
import Tab5 from "@/components/Settings/Bankings/Dialog/Tab5";
import Tab6 from "@/components/Settings/Bankings/Dialog/Tab6";

export default {
  name: "EditBankingDialog",
  components: {
    'Tab1': Tab1,
    'Tab2': Tab2,
    'Tab3': Tab3,
    'Tab4': Tab4,
    'Tab5': Tab5,
    'Tab6': Tab6,
  },
  props: {
    show: Boolean,
    banking: Object,
  },
  mixins: [generalMixin],
  data() {
    return {
      activeIndex: 0,
      overlay: false,
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    }
  },
  computed: {
    computedVisible: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close')
      }
    },
  }
}
</script>

<style scoped lang="scss">

.icon {
  //color: var(--green-banklot);
}

.tab-title {
  color: var(--green-banklot);
}
</style>
