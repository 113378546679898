<template>
  <Prizes/>
  <div class="grid">

    <div class="col-12 xl:col-6">
      <RecentSales/>
      <MostSold/>
<!--      <Database/>-->
    </div>
    <div class="col-12 lg:col-6 xl:col-6">
      <DonutSales/>
<!--      <HistoricSales/>-->
    </div>
  </div>

  <Toast/>

</template>

<script>
import EventBus from "@/app/AppEventBus";
import SaleService from "@/service/backend/SaleService";
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import StatsService from "@/service/backend/StatsService";
import Prizes from "@/components/Dashboard/components/Prizes";
import RecentSales from "@/components/Dashboard/components/RecentSales";
import DonutSales from "@/components/Dashboard/components/DonutSales";
import MostSold from "@/components/Dashboard/components/MostSold";
// import Database from "@/components/Dashboard/components/Database";


export default {
  components: {
    // Database,
    "Prizes": Prizes,
    "RecentSales": RecentSales,
    // "HistoricSales": HistoricSales,
    "DonutSales": DonutSales,
    "MostSold": MostSold,
  },
  mixins: [generalMixin],
  created() {
    this.saleService = new SaleService();
    this.mainService = new MainService();
    this.statsService = new StatsService();
    this.identity = this.getIdentity()
  },
  data() {
    return {
      chartData: {
        labels: ['A', 'B', 'C'],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
          }
        ]
      },
      pieOptions: null,
      numbersData: null,
      tripleWidth: null,
      displayTriples: false,
      identity: null,
      cursor: 'default',
    }
  },
  themeChangeListener: null,
  async mounted() {
    this.loadingRecent = true;

    this.loading1 = false;
    this.tripleWidth = "width: 3%;";
  },
  beforeUnmount() {
    EventBus.off('change-theme', this.themeChangeListener);
  },
}


</script>
<style scoped lang="scss">
.overlay {
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.spinner {
  width: 80px;
  height: 80px;
}
.block {
  border-radius: 5px;
}
.ball {
  border: solid 1px black;
  background-color: white;
  color: black;
  font-weight: bold;
  padding: 0 2px 0 2px;
  border-radius: 5px;
  margin: 2px;
}
.percent {
  min-width: 10px;
}
.legend-square {
  width: 10px;
  height: 10px;
  margin: 4px 10px 0 10px;
}
</style>