<template>
  <div>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <div class="block_container">
            <div class="bloc1"><h3>Usuarios {{ users && '(' + users.length + ')' }}</h3></div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-2">
        <div class="card p-fluid" id="number-container">
          <!--     Filter goes here     -->
          <Filter
              @get-data="getData"
              page="users"
          />
        </div>
      </div>

      <div class="col-12 lg:col-10">
        <div class="card">
          <Toolbar class="mb-4">
            <template #start>
              <Button label="" icon="pi pi-plus" class="p-button-success mr-2" @click="createUser"/>
            </template>
            <template #end>
              <Button label="" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                      :disabled="!selectedUsers || !selectedUsers.length"/>
            </template>
          </Toolbar>

          <DataTable :value="users"
                     v-model:selection="selectedUsers"
                     responsiveLayout="scroll"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table"
                     :paginator="true"
                     :rows="10"
                     :filters="filters"
                     :loading="loadingTable"
                     showGridlines
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     :rowsPerPageOptions="[5,10,25,50]"
                     currentPageReportTemplate="{first} al {last} de {totalRecords}"
                     :key="componentKey">

            <template #header>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <h5 class="mb-2 md:m-0 p-as-md-center">Usuarios</h5>
                <span class="p-input-icon-left">
                            <i class="pi pi-search"/>
                            <InputText v-model="filters['global'].value" placeholder="Buscar..."/>
                        </span>
              </div>
            </template>
            <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

            <Column field="status_id" header="Estatus" :sortable="true"
                    bodyStyle="text-align: center; white-space: nowrap !important;">
              <template #body="{data}">
                <span :class="'status-badge '+getUserStatusClass(data.status_id)" v-if="!data.locked">
                  {{ getUserStatus(data.status_id, data.locked) }}
                </span>
                <span class="status-badge warning" v-if="data.locked">
                  BLOQUEADO
                </span>

              </template>
            </Column>

            <Column field="name" header="Nombre" :sortable="true" style="min-width:12rem"></Column>
            <Column field="email" header="Correo Electrónico" :sortable="true" style="min-width:12rem"></Column>

            <Column field="role_id" header="Nivel de Acceso" :sortable="true" style="min-width:10rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ getUserRoles(data.role_id) }}
              </template>
            </Column>

            <Column field="entity_name" header="Entidad" :sortable="true" style="min-width:4rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ data.entity_name }}
              </template>
            </Column>

            <Column field="createdAt" header="Fecha de creación" :sortable="true" style="min-width:4rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ formatDate(data.createdAt) }}
              </template>
            </Column>

            <Column field="lastLoginAt" header="Último acceso" :sortable="true" style="min-width:8rem">
              <template #body="{data}">
                {{ formatDateAndtime(data.lastLoginAt) }}
              </template>
            </Column>

            <Column field="cstatus_agencia" header="Acción" style="width:10px">
              <template #body="props">
                <div class="flex justify-content-between">
                  <span class="p-row-editor-init-icon pi pi-fw pi-lock edit-icon"
                        v-tooltip.bottom="'Bloquear'"
                        v-if="props.data.cstatus_agencia!==4"
                        v-bind:class="{'locked': props.data.locked, 'unlocked': !props.data.locked}"
                        @click="confirmBlockUser(props.data)"></span>
                  <span class="p-row-editor-init-icon pi pi-fw pi-pencil edit-icon"
                        v-tooltip.bottom="'Editar'"
                        @click="editUser(props.data)"/>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>

        <Dialog v-model:visible="blockUserDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="user">¿{{
                this.user.locked ? 'Desbloquear' : 'Bloquear'
              }} usuario <b>{{ user && user.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="blockUserDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="blockUser(user)"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteUsersDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="user">¿Borrar los usuarios seleccionados?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUsersDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelectedUsers"/>
          </template>
        </Dialog>
      </div>
    </div>
  </div>

  <EditUserDialog
      :user="user"
      :show="displayUserDialog"
      ref="editUserDialog"
      @new-user="pushNewUser"
      @close-reload="closeUserDialogAndReload"
      @close="closeUserDialog"/>


  <Toast/>

</template>


<script>
import {FilterMatchMode} from 'primevue/api';
import MainService from "@/service/backend/MainService";
import UserService from "@/service/backend/UserService";
import generalMixin from "@/mixins/generalMixin";
import Filter from "@/components/Filters/Filter";
import EditUserDialog from "@/components/Settings/Users/Dialog/EditUserDialog";

export default {
  components: {
    'Filter': Filter,
    'EditUserDialog': EditUserDialog,
  },
  mixins: [generalMixin],

  data() {
    return {
      users: null,
      userDialog: false,
      blockUserDialog: false,
      deleteUsersDialog: false,
      user: {},
      selectedUsers: null,
      filters: {},
      submitted: false,
      componentKey: 0,

      loadingTable: true,
      nonEmptyField: 'Este campo es obligatorio',
      displayUserDialog: false,

      editingRows: false,
      clicked: false,
      identity: null,
    }
  },
  userService: null,
  created() {
    this.userService = new UserService();
    this.mainService = new MainService();
    this.initFilters();
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData(filters) {
      this.loadingTable = true;
      this.users = await this.userService.getAll({
        identity: this.getIdentity(),
        filters: filters,
      });
      // Prevent issues by removing user himself from the list (if not an admin)
      if (this.identity.roleId !== 1) {
        this.users= this.users.filter(val => val.email !== this.$store.getters.getProfile.email);
      }

      this.loadingTable = false;
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
    },
    createUser() {
      this.user = {}
      this.$store.commit('setUser', {});
      this.$router.push('/usuarios/agregar-1');
    },
    editUser(user) {
      this.user = {...user};
      this.$refs.editUserDialog.loadForm(user);
      this.submitted = false;
      this.displayUserDialog = true;
    },
    confirmBlockUser(user) {
      this.blockUserDialog = true;
      this.user = user;
    },
    async blockUser(user) {
      this.blockUserDialog = false;
      this.loadingTable = true;
      const response = await this.userService.block({id: user.id});
      if (response.error) {
        this.$toast.add({
          severity: 'success',
          summary: ``,
          detail: `El usuario ${user.name} no pudo ser ${!user.locked ? 'bloqueado' : 'desbloqueado'} | ---` + response.error,
          life: 3000
        });
      } else {
        this.$toast.add({
          severity: 'success',
          summary: ``,
          detail: `Usuario ${user.name} ${!user.locked ? 'bloqueado' : 'desbloqueado'}`,
          life: 3000
        });
      }
      await this.getData();
      this.loadingTable = false;
    },
    confirmDeleteSelected() {
      this.deleteUsersDialog = true;
    },
    deleteSelectedUsers() {
      this.loadingTable = true;
      this.userService.delete(this.selectedUsers);
      this.users = this.users.filter(val => !this.selectedUsers.includes(val));
      this.deleteUsersDialog = false;
      this.selectedUsers = null

      this.loadingTable = false;
      this.$toast.add({severity: 'success', summary: '', detail: 'Usuarios eliminados', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    validatedForm() {
      return this.user.name ? true : false;
    },
    closeUserDialog() {
      this.getData();
      this.displayUserDialog = false;
    },
    pushNewUser(value) {
      this.users.push(value);
      this.closeUserDialog();
    },
    closeUserDialogAndReload() {
      this.getData();
      this.closeUserDialog();
    },
  },

}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.user-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .user-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

#role, #status {
  margin-top: 10px;
}

.field {
  padding: 0 20px 0 20px;
}

.locked {
  //background-color: #ffc107;
  color: #ffc107;
  //color: black;
}

.unlocked {
  //background-color: var(--surface-400);
  border-color: var(--surface-400);
}

.status-badge {
  width: 70px;
}
</style>