<template>
  <form @submit.prevent="confirmSaveData" class="p-fluid" autocomplete="off">
    <div class="flex justify-content-center">


      <Accordion style="width: 100%;" :multiple="true" :activeIndex="0">
        <AccordionTab header="Lotería">

          <Dropdown v-model="selectedPaymentProfile"
                    :options="paymentProfiles"
                    optionLabel="tperfil_pago_premios"
                    optionValue="id_perfil"
                    style="width: 100%; margin-bottom: 10px;"
                    :onChange="getData"
                    placeholder="Seleccionar perfil de pago"/>

          <DataTable :value="percentages && percentages.loteria"
                     editMode="row" dataKey="id"
                     v-model:editingRows="editingRows"
                     @row-edit-save="onRowEditSaveLoteria"
                     responsiveLayout="scroll"
                     :loading="loadingTable"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">

            <Column field="tperfil_pago_premios" header="Perfil de pago" style="text-align: left;"/>
            <template #body>
              {{ selectedPaymentProfile.tperfil_pago_premios }}%
            </template>
            <Column field="tlista" header="Lista" style="text-align: left;"/>
            <Column field="nporcentaje_comision" header="Porcentaje" style="text-align: center;">
              <template #body="{data}">
                {{ formatNumber(data.nporcentaje_comision) }}%
              </template>
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" suffix="%" :min="0" :max="40" showButtons/>
              </template>
            </Column>
            <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
          </DataTable>
        </AccordionTab>

        <AccordionTab header="Animalitos">
          <DataTable :value="percentages && percentages.animalitos" editMode="row" dataKey="id"
                     v-model:editingRows="editingRows"
                     @row-edit-save="onRowEditSaveAnimalitos" responsiveLayout="scroll"
                     :loading="loadingTable"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">
            <Column field="tperfil_pago_premios" header="Perfil de pago" style="text-align: left;"/>
            <Column field="tlista" header="Lista" style="text-align: left;"/>
            <Column field="nporcentaje_comision" header="Porcentaje" style="text-align: center;">
              <template #body="{data}">
                {{ formatNumber(data.nporcentaje_comision) }}%
              </template>
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" suffix="%" :min="0" :max="40" showButtons/>
              </template>
            </Column>
<!--            <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>-->
          </DataTable>
        </AccordionTab>
      </Accordion>
    </div>

<!--    <div class="flex justify-content-end" style="margin-top: 10px;">-->
<!--      <Button-->
<!--          class="p-button-raised" icon="pi pi-save"-->
<!--          type="submit"-->
<!--          :disabled="!bDirty"-->
<!--      />-->
<!--    </div>-->
  </form>

  <Dialog v-model:visible="savePercentagesDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>¿Está seguro que quiere editar los porcentajes seleccionados?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="savePercentagesDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="saveData"/>
    </template>
  </Dialog>
</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import BlockService from "@/service/backend/BlockService";

export default {
  name: "Tab2",
  mixins: [generalMixin],
  created() {
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
    this.blockService = new BlockService;
  },
  props: {
    block: Object,
  },
  data() {
    return {
      percentages: null,
      paymentProfiles: null,
      editingRows: [],
      loadingTable: false,
      selectedPaymentProfile: null,
      bDirty: false,
      active: 0,
      savePercentagesDialog: false,
    }
  },
  async mounted() {
    this.paymentProfiles = await this.blockService.getPaymentProfile({
      id_comercializador: this.block.id_comercializador,
    });
    this.selectedPaymentProfile = this.paymentProfiles[0].id_perfil;
    await this.getData();
  },
  methods: {
    async getData() {
      this.loadingTable = true;
      this.percentages = await this.blockService.getBlockPercentages({
        id_comercializador: this.block.id_comercializador,
        id_perfil_premiosxporcentajes: this.selectedPaymentProfile,
        id_psxcmxban: this.block.id_psxcmxban,
      });
      this.loadingTable = false;
    },
    onRowEditSaveLoteria(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.percentages.loteria[index] = newData;
    },
    onRowEditSaveAnimalitos(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.percentages.animalitos[index] = newData;
    },
    confirmSaveData() {
      this.savePercentagesDialog = true;
    },
    async saveData() {
      let res = [];

      this.percentages.loteria.forEach((item) => {
        res.porcentaje_participacion_dis = Number(item.nporcentaje_participacion);
        res.porcentaje_regalia_dis = Number(item.nporcentaje_regalia);
        switch (item.id_lista) {
          case 0: // Terminales
            res.ter = Number(item.nporcentaje_comision);
            break;
          case 1: // Triples
            res.tri = Number(item.nporcentaje_comision);
            break;
          case 2: // Terminales con signo
            res.teresp = Number(item.nporcentaje_comision);
            break;
          case 3: // Triples con signo
            res.triesp = Number(item.nporcentaje_comision);
            break;
        }
      });

      const response = await this.blockService.updateBlockPercentages({
        // Loteria
        id_psxcmxbanxdis: this.block.id_psxcmxbanxdis,
        id_perfil: this.percentages.loteria[0].id_perfil_premiosxprocentajes,
        porcentaje_terminal: res.ter,
        porcentaje_triple: res.tri,
        porcentaje_terminal_s: res.teresp,
        porcentaje_triple_s: res.triesp,
        porcentaje_participacion_dis: res.porcentaje_participacion_dis,
        porcentaje_regalia_dis: res.porcentaje_regalia_dis,
        // Animalitos
        nporcentaje_comision: Number(this.percentages.animalitos[0].nporcentaje_comision),
        id_prestador_servicio: 100,
        id_comercializador: this.block.id_comercializador,
      });

      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `No se pudo editar los porcentajes del comercializador ${this.block.tcomercializador}| ` + response.error,
          life: 10000
        });
        this.overlay = false;
      } else {
        this.$toast.add({
          severity: 'success',
          summary: '',
          detail: `Porcentajes de comercializador ${this.block.tcomercializador} editados`,
          life: 3000
        });
        this.$emit('close', close);
      }

    }

  }
}
</script>

<style scoped lang="scss">


</style>