<template>

  <Toast/>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>

  <Card style="border-radius:12px;">
    <template v-slot:title>
      Nombre
    </template>
    <template v-slot:subtitle>
      Añadir usuario
    </template>

    <template #content>
      <div class="filters">

        <!--          Name-->
        <div class="field">
          <div class="p-float-label">
            <InputText id=name
                       placeholder="Nombre"
                       v-model="name"
                       autocomplete="off"
                       style="width:100%;"/>
          </div>
        </div>
        <!--          Email-->
        <div class="field">
          <div class="p-float-label">
            <InputText id=email
                       placeholder="Correo Electrónico"
                       v-model="email"
                       autocomplete="off"
                       style="width:100%;"/>
          </div>
        </div>

        <!--          Email-->
        <div class="field">
          <div class="p-float-label">
            <Password id=password
                      class="password"
                       placeholder="Contraseña"
                       v-model="password"
                       autocomplete="off"
                      :feedback="false"
                      :toggleMask="true"
                       style="width:100%;"/>
          </div>
        </div>
      </div>


    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-end">
        <!--          <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" />-->
        <Button label="Siguiente"
                @click="nextPage()"
                icon="pi pi-angle-right"
                iconPos="right"
                :disabled="this.name && this.email && this.password ? disabled : ''"
        />
      </div>
    </template>
  </Card>

  <router-view v-slot="{Component}"
               @prevPage="prevPage($event)"
               @nextPage="nextPage($event)">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>

</template>

<script>


import UserService from "@/service/backend/UserService";

export default {
  name: "Step1",
  created() {
    // this.distributorName = this.$store.getters.getDistributor.distributorName;
    this.userService = new UserService;
  },
  mounted() {
    this.name = null;//this.$store.getters.getUser.name;
    this.email = null;//this.$store.getters.getUser.email;
    this.password = null;//this.$store.getters.getUser.password;

  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/usuarios/agregar-1'
        },
        {
          label: 'Nivel de Acceso',
          to: '/usuarios/agregar-2'
        },
      ],
      name: null,
      email: null,
      password: null,

      pageIndex: 0,
      roleId: null,
      userService: null,
    }
  },

  methods: {
    async nextPage() {
      const existingUser = await this.checkEmailDoesntExist();
      if (!existingUser) {
        this.$store.commit('setUser', {
          name: this.name,
          email: this.email,
          password: this.password,
          id_comercializador: this.$store.getters.getUser.id_comercializador,
          id_banca: this.$store.getters.getUser.id_banca,
          id_distribuidor: this.$store.getters.getUser.id_distribuidor,
        });
        this.$router.push({name: 'usuarios/agregar-2',})
      } else {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'Este correo ya existe. Por favor seleccione otro.',
          life: 10000
        });
        this.$router.push({name: 'usuarios/agregar-1',})
      }

    },
    async checkEmailDoesntExist() {
      return await this.userService.exists({email: this.email})
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 2px 0 5px 0;
}

::v-deep(.password  .p-inputtext) {
  width: 100% !important;
}

</style>