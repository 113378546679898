<template>
  <Dialog :header="header"
          class="ticket-dialog"
          v-model:visible="computedVisible"
          :modal="true"
          :closable="true">

    <slot/>
    <div class="grid">
      <div class="card">
        <DataTable :value="data"
                   class="p-datatable-sm p-datatable-gridlines p-datatable-striped"
                   :loading="loading1"
                   :paginator="true"
                   :rows="10"
                   :footer="getTotals"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]" #
                   currentPageReportTemplate="{first} al {last} de {totalRecords}">
          <ColumnGroup type="header">
            <Row>
              <Column header="Ticket" :sortable="true" field="tticket" bodyStyle="text-align: center"
                      footerStyle="text-align: center">
                <template #body="{data}">
                  <Button
                      class="t-tickets"
                      type="button"
                      :style="getTicketDialogColour2(data.mmonto_aceptado)"
                      :label="data.tticket"
                      @click="openTicketDetail(data)"
                  />
                </template>
              </Column>
              <Column header="Hora" :sortable="true" field="hora" bodyStyle="text-align: left" footer="TOTAL:">
              </Column>
              <Column header="Apuestas" :sortable="true" field="apuestas" bodyStyle="text-align: center"
                      footerStyle="text-align: center" :footer="getTotals.apuestas"/>
              <Column header="Monto" :sortable="true" field="mmonto_aceptado" bodyStyle="text-align: right"
                      footerStyle="text-align: right" :footer="formatCurrency(getTotals.monto).toString()">
                <template #body="{data}">
                  {{ formatCurrency(data.mmonto_aceptado) }}
                </template>
              </Column>
              <Column header="Bloque" :sortable="true" field="tcomercializador" bodyStyle="text-align: left"/>
              <Column header="Banca" :sortable="true" field="tbanca" bodyStyle="text-align: left"/>
              <Column header="Distribuidor" :sortable="true" field="tdistribuidor" bodyStyle="text-align: left"/>
              <Column header="Punto de Venta" :sortable="true" field="tagencia" bodyStyle="text-align: left"/>
              <Column header="Estatus" :sortable="true" field="tstatus" bodyStyle="text-align: left">
                <template #body="{data}">
                  <div :class="statusClass(data)">
                    {{ data.tstatus }}
                  </div>
                </template>
              </Column>
            </Row>
          </ColumnGroup>

        </DataTable>
      </div>
    </div>
  </Dialog>

  <TicketDetailDialog
      :header="detailHeader"
      :status="detailStatus"
      :data="ticketDetailData"
      :winner="header"
      :game="game"
      :show="openTicketDetailDialog"
      @close-detail="closeTicketDetailDialog"/>

</template>

<script>
import TicketDetailDialog from "@/components/Sales/TicketDetailDialog";

import generalMixin from "@/mixins/generalMixin";
import TicketService from "@/service/backend/TicketService";

export default {
  components: {
    'TicketDetailDialog': TicketDetailDialog,
    // 'ProgressSpinner': ProgressSpinner,
  },
  mixins: [generalMixin],
  created() {
    this.ticketService = new TicketService();
  },
  data() {
    return {
      ticketData: [],
      loading1: false,
      selectedTransactionId: null,
      selectedTransactionStatus: null,
      ticketDetailData: [],
      openTicketDetailDialog: false,
      detailHeader: null,
      detailStatus: null,
      ticketService: null,
      max: 0,
    }
  },
  props: {
    show: Boolean,
    header: String,
    data: Object,
    game: String,
  },
  mounted() {
    this.max = this.data && this.data.max;

  },
  updated() {
    this.max = 0;
    let amount;
    this.data.forEach((item) => {
      amount = Number(item.mmonto_aceptado);
      // this.max = (amount > this.max)?amount:this.max;
      this.max += amount;
    })
    this.max = amount / this.data.length;
  },
  methods: {
    closeDialog() {
      this.computedVisible = false;
      this.$emit('close')
    },
    closeTicketDetailDialog() {
      this.openTicketDetailDialog = false;
      this.$emit('close-detail')
    },

    async openTicketDetail(params) {
      const currentDate = new Date(params.dtransaccion);
      const options = {
        game: this.game,
        fecha: currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate(),
        id_transaccion: params.id_transaccion ? params.id_transaccion : null,
        numero: this.header,
        id_transaccion_ruleta: params.id_transaccion_ruleta ? params.id_transaccion_ruleta : null,
      };
      this.ticketDetailData = await this.ticketService.getTicketReportDetail(options);
      this.detailHeader = {ticket: params.tticket, number: this.header};
      this.detailStatus = params.tstatus;
      this.openTicketDetailDialog = true;
    },
    getTicketDialogColour2(value) {
      let num = 0;
      let response = 0;
      try {
        num = value * 100;
        num = Math.ceil(num / 100) * 100;

        if (num > 800)
          response = "min-width:105px; color: white; background-color: var(--green-" + 900 + ")";
        else if (num > 400)
          response = "min-width:105px; color: white; background-color: var(--green-" + num + ")";
        else
          response = "min-width:105px; color: black; background-color: var(--green-" + num + ")";

        return response;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  }
  ,
  computed: {
    computedVisible: {
      get() {
        return this.show
      }
      ,
      set() {
        this.$emit('close')
      }
    }
    ,
    getTicketNumber() {
      return this.header;
    }
    ,
    getTotals() {
      let totalApuestas = 0;
      let totalMonto = 0;
      if (this.data) {
        for (let item of this.data) {
          totalApuestas += Number(item.apuestas)
          // Look up table admin.tb_status_transaccion
          // if (item.tstatus !== 'ANULADO' || item.tstatus !== 'REVERSADO FUERA DE TIEMPO') {
          if (item.cstatus_transaccion !== 1 && item.cstatus_transaccion !== 3) {
            totalMonto += Number(item.mmonto_aceptado)
          }
        }
      }

      return {
        tickets: Object.keys(this.data).length,
        apuestas: totalApuestas,
        monto: totalMonto.toFixed(2),
      };
    }
    ,

  }
  ,
}
;
</script>
<style>
/*.p-dialog-title {*/
.ticket-dialog .p-dialog-title {
  background-color: #004a49;
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  color: white;
}


::v-deep(.row-accessories) {
  background-color: rgba(0, 0, 0, .15) !important;
}

</style>