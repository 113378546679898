<template>
  <div>

    <DataTable :value="misc && misc.misc"
               editMode="row" dataKey="id"
               v-model:editingRows="editingRows"
               @row-edit-save="onRowEditSaveMisc"
               responsiveLayout="scroll"
               :loading="loadingTable"
               class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">

      <Column field="tlista" header="Descripción" style="text-align: left;"/>
      <Column field="code" header="Valor" style="text-align: center;">
        <template #body="{data}">
          {{ data.code }}
        </template>
      </Column>
    </DataTable>

  </div>

<!--  <div class="flex justify-content-end">-->
<!--    <Button icon="pi pi-print" class="p-button-raised" :onClick="print"/>-->
<!--  </div>-->

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import BlockService from "@/service/backend/BlockService";

export default {
  name: "Tab5",
  mixins: [generalMixin],
  created() {
    this.blockService = new BlockService;
  },
  props: {
    block: Object,
  },
  data() {
    return {
      loadingTable: false,
      editingRows: [],
      misc: null,
    }
  },
  mounted() {
    this.getMisc();
  },
  methods: {
    async getMisc() {
      this.misc = await this.blockService.getBlockMiscellaneous({
        id_comercializador: this.block.id_comercializador,
        id_psxcm: this.block.id_psxcm,
      });
    },
    onRowEditSaveMisc(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.misc.loteria[index] = newData;
    },
  }

}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
}
.input-text {
  margin: 30px 0 30px 0;
}
.address {
  border: solid 1px lightgray;
  border-radius: 5px;
  margin-top: 5px;
  padding: 15px;
}
.address-label {
  //top: -0.75rem;
  margin-left: 12px;
  font-size: 12px;
  color: gray;
}
.container {
  display: flex;
  /*padding: 10px;*/
  border: solid 1px var(--surface-300);
  margin: 35px 0 15px 0;
  .title {
    margin-right: 10px;
    background-color: var(--primary-color2);
    color: white;
    padding: 10px 10px 10px 15px;
    min-width: 140px;
    font-weight: bold;
  }
  .content {
    padding: 10px 0 0 0;
    min-width: 70px;
    text-align: center;
    font-weight: bold;
  }
}
.table {
  display: block;
}
.row {
  display: block;
}
.cell {
  display: inline-block;
}
.p-button-raised {
  margin-top: 10px;
}
</style>