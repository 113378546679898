<template>
  <form @submit.prevent="confirmSavebankingsData" class="p-fluid" autocomplete="off" id="bankings">
    <Accordion>
      <AccordionTab>
        <template #header>
          <span>Lotería</span>
        </template>

        <div>
          <Dropdown v-model="selectedPaymentProfile"
                    :options="paymentProfiles"
                    optionLabel="tperfil_pago_premios"
                    style="width: 100%; margin-bottom: 10px;"
                    :onChange="getData"
                    :scrollable="true"
                    scrollHeight="400px"
                    placeholder="Seleccionar perfil de pago"/>

          <DataTable :value="bankings && bankings.lottery"
                     editMode="cell"
                     @cell-edit-complete="onCellEditComplete"
                     :scrollable="true"
                     dataKey="id"
                     class="editable-cells-table p-datatable-gridlines p-datatable-striped p-datatable-sm"
                     filterDisplay="row"
                     :loading="loadingTable"
                     responsiveLayout="scroll">

            <template #loading>
              <div style="font-size: large; font-weight: bold; color: var(--yellow-500);">
                Cargando agencias. Por favor espere.
              </div>

            </template>
            <Column field="id"
                    header=""
                    header-style="max-width:30px; text-align: center;"
                    filter-header-style="max-width:30px; text-align: center; background-color: var(--surface-e);"
                    body-style="max-width:30px; text-align: center;"/>
            <Column field="tbanca"
                    header="Banca"
                    headerStyle="min-width:250px; text-align: left;"
                    filter-header-style="min-width:250px;text-align: left; background-color: var(--surface-e);"
                    body-style="min-width:250px; text-align: left;"/>
            <Column field="tperfil_pago_premios"
                    header="Perfil de pago"
                    headerStyle="width:200px; text-align: center;!important"
                    filter-header-style="width:200px; text-align: center; background-color: var(--surface-e);"
                    bodyStyle="text-align: center;"/>

            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                    class="fieldColumns">
              <template #filter="{field}">
                <div class="header">
                  <div @click="toggleMain(field)" v-if="main && !editField[field]">
                    {{ formatNumber(main[field]) }}%
                  </div>
                  <div class="flex justify-content-center" v-if="editField[field]">
                    <InputNumber v-model="main[field]"
                                 mode="decimal" showButtons
                                 buttonLayout="vertical"
                                 input-style="width: 50px;"
                                 suffix="%"
                                 incrementButtonIcon="pi pi-plus"
                                 decrementButtonIcon="pi pi-minus"
                                 :onchange="unToggleMain(field)"/>
                  </div>
                </div>
              </template>
              <template #body="{data, field}">
                {{ formatNumber(data[field]) }}%
              </template>
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"
                             mode="decimal" showButtons
                             buttonLayout="vertical"
                             input-style="width: 50px;"
                             suffix="%"
                             incrementButtonIcon="pi pi-plus"
                             decrementButtonIcon="pi pi-minus"
                />
              </template>
            </Column>
          </DataTable>

        </div>


      </AccordionTab>

      <AccordionTab>
        <template #header>
          <span>Animalitos</span>
        </template>
        <DataTable :value="bankings && bankings.animalitos"
                   editMode="cell"
                   @cell-edit-complete="onCellEditCompleteAnimalitos"
                   :scrollable="true"
                   dataKey="id"
                   class="editable-cells-table p-datatable-gridlines p-datatable-striped p-datatable-sm"
                   filterDisplay="row"
                   :loading="loadingTable"
                   responsiveLayout="scroll">

          <template #loading>
            <div style="font-size: large; font-weight: bold; color: var(--yellow-500);">
              Cargando agencias. Por favor espere.
            </div>

          </template>
          <Column field="id"
                  header=""
                  header-style="max-width:30px; text-align: center;"
                  filter-header-style="max-width:30px; text-align: center; background-color: var(--surface-e);"
                  body-style="max-width:30px; text-align: center;"/>
          <Column field="tbanca"
                  header="Banca"
                  headerStyle="min-width:250px; text-align: left;"
                  filter-header-style="min-width:250px;text-align: left; background-color: var(--surface-e);"
                  body-style="min-width:250px; text-align: left;"/>
          <Column field="nporcentaje_comision"
                  header="Porcentaje"
                  class="fieldColumns">
            <template #filter>
              <div class="header">
                <div @click="toggleMain('animalitos')" v-if="main && !editField['animalitos']">
                  {{ formatNumber(main['animalitos']) }}%
                </div>
                <div class="flex justify-content-center" v-if="editField['animalitos']">
                  <InputNumber v-model="main['animalitos']"
                               mode="decimal" showButtons
                               buttonLayout="vertical"
                               input-style="width: 50px;"
                               suffix="%"
                               incrementButtonIcon="pi pi-plus"
                               decrementButtonIcon="pi pi-minus"
                               :onchange="unToggleAnimalitos()"/>
                </div>
              </div>
            </template>
            <template #body="{data, field}">
              {{ formatNumber(data[field]) }}%
            </template>
            <template #editor="{ data, field }">
              <InputNumber v-model="data[field]"
                           mode="decimal" showButtons
                           buttonLayout="vertical"
                           input-style="width: 50px;"
                           suffix="%"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus"
              />
            </template>
          </Column>
        </DataTable>
      </AccordionTab>
    </Accordion>
    <div class="flex justify-content-end" style="margin-top: 10px;">
      <Button
          class="p-button-raised" icon="pi pi-save"
          type="submit"
          :disabled="!bDirty"
      />
    </div>
  </form>

  <Dialog v-model:visible="savePercentagesDialog" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>¿Está seguro que quiere editar los porcentajes seleccionados?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="savePercentagesDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="saveData"/>
    </template>
  </Dialog>


</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import BlockService from "@/service/backend/BlockService";
import BankingService from "@/service/backend/BankingService";

export default {
  name: "Tab6",
  mixins: [generalMixin],

  created() {
    this.blockService = new BlockService;
    this.bankingService = new BankingService;
    this.columns = [
      {field: 'triple', header: 'Triple'},
      {field: 'terminal', header: 'Terminal'},
      {field: 'triple_signo', header: 'Triple con Signo'},
      {field: 'terminal_signo', header: 'Terminal con Signo'}
    ];
  },
  props: {
    block: Object,
  },
  data() {
    return {
      bankings: null,
      loadingTable: false,
      editingRows: [],
      selectedPaymentProfile: null,
      paymentProfiles: [],
      columns: null,
      confirmCopyPercentages: false,
      field: null,
      newValue: null,
      main: null,
      editField: {
        triple: false,
        terminal: false,
        triple_signo: false,
        terminal_signo: false,
        animalitos: false,
      },
      currentField: null,
      bDirty: false,
      savePercentagesDialog: false,

    }
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.loadingTable = true;
      this.paymentProfiles = await this.bankingService.getPaymentProfile({
        id_comercializador: this.block.id_comercializador,
        id_banca: this.block.id_banca,
      });
      this.selectedPaymentProfile = this.paymentProfiles[0];

      this.bankings = await this.blockService.getBankings({
        id_comercializador: this.block.id_comercializador,
        id_perfil_premiosxprocentajes: this.selectedPaymentProfile.id_perfil
      });
      this.loadingTable = false;
      // alert(1);
      this.main = {
        triple: Number(this.bankings.lottery[0].triple),
        terminal: Number(this.bankings.lottery[0].terminal),
        triple_signo: Number(this.bankings.lottery[0].triple_signo),
        terminal_signo: Number(this.bankings.lottery[0].terminal_signo),
        animalitos: Number(this.bankings.animalitos[0].nporcentaje_comision),
      };
    },
    onCellEditComplete(event) {
      this.field = event.field;
      this.newValue = event.newValue;
      // Add new values to table
      let {newData, index} = event;
      this.bankings.lottery[index] = newData;
      this.bDirty = true;
    },
    onCellEditCompleteAnimalitos(event) {
      this.field = event.field;
      this.newValue = event.newValue;
      // Add new values to table
      let {newData, index} = event;
      this.bankings.animalitos[index] = newData;
      this.bDirty = true;
    },
    toggleMain(field) {
      this.editField[field] = true;
    },
    unToggleMain(field) {
      this.currentField = (this.currentField === null) ? field : this.currentField;
      if (this.currentField !== field) {
        this.editField[this.currentField] = false;
        this.currentField = field;
      } else {
        for (let i = 0; i < this.bankings.lottery.length; i++) {
          this.bankings.lottery[i][field] = this.main[field];
        }
        this.bDirty = true;
      }
      return true;
    },
    unToggleAnimalitos() {
      for (let i = 0; i < this.bankings.animalitos.length; i++) {
        this.bankings.animalitos[i]['nporcentaje_comision'] = this.main['animalitos'];
      }
      this.bDirty = true;
      return true;
    },
    confirmSavebankingsData() {
      this.savePercentagesDialog = true;
    },
    async saveData() {

      for (let i = 0; i < this.bankings.lottery.length; i++) {

        let lottery = this.bankings.lottery[i];
        let animalitos = this.bankings.animalitos[i];

        let response = await this.bankingService.updateBankingPercentages({
          // Loteria
          id_psxcmxban: lottery.id_psxcmxban,
          id_perfil_premiosxporcentajes: this.selectedPaymentProfile.id_perfil,
          porcentaje_terminal: lottery.terminal,
          porcentaje_triple: lottery.triple,
          porcentaje_terminal_s: lottery.terminal_signo,
          porcentaje_triple_s: lottery.triple_signo,
          porcentaje_participacion: 0,
          porcentaje_regalia: 0,
          // Animalitos
          id_prestador_servicio: 100,
          id_comercializador: this.block.id_comercializador,
          id_banca: animalitos.id_banca,
          porcentaje_comision_animalitos: animalitos.nporcentaje_comision,
          porcentaje_participacion_animalitos: 0,
          porcentaje_regalia_animalitos: 0,
          id_perfil_animalitos: 1,
        });


        if (response.error) {
          this.$toast.add({
            severity: 'error',
            summary: '',
            detail: `No se pudo editar los porcentajes del distribuidor ${this.bankings.lottery[i].tdistribuidor}| ` + response.error,
            life: 10000
          });
          this.overlay = false;
        } else {
          this.$toast.add({
            severity: 'success',
            summary: '',
            detail: `Porcentajes de distribuidor ${this.bankings.lottery[i].tdistribuidor} editados`,
            life: 3000
          });
        }
        this.savePercentagesDialog = false;
        // this.$emit('close', close);
      }
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep( tr ) {
  > th {
    display: flex;
    justify-content: center;
    min-height: 40px;
    // Remove funnel
    .p-column-filter-row .p-link {
      display: none;
    }

    .p-fluid .p-inputnumber {
      width: 50px;
    }
  }

  > td {
    display: flex;
    justify-content: center;
  }
}

::v-deep(.p-datatable.p-datatable-gridlines.p-datatable-scrollable .p-datatable-tbody > tr + tr > td, .p-datatable.p-datatable-gridlines.p-datatable-scrollable .p-datatable-tbody > tr:first-child > td) {
  display: grid;
}

::v-deep( .fieldColumns ) {
  text-align: center !important;
}

::v-deep( .p-accordion-header) {
  margin: 5px;
}

::v-deep(.p-inputnumber-buttons-vertical .p-button ) {
  width: 50px;
  display: flex;
  justify-content: center;
}

::v-deep(.p-filter-column .fieldColumns) {
  background-color: var(--surface-e) !important;
}

::v-deep(.p-fluid .p-inputnumber) {
  width: unset;
}
</style>