<template>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="block_container">
          <div class="bloc1"><h5>Tickets Vendidos ({{ totalRecords }})</h5></div>

          <div class="bloc2"><h3>Total:
            {{ stats.mmonto_aceptado ? formatCurrency(Number(stats.mmonto_aceptado)) : formatCurrency(0) }}</h3></div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-2">
      <div class="card p-fluid" id="number-container">
        <!--     Filter goes here     -->
        <Filter
            @get-data="loadLazyData"
            page="sold"
            v-if="identity.roleId!==7"/>

        <FilterAnalyst
            @get-data="loadLazyData"
            page="sold"
            v-if="identity.roleId===7"/>

      </div>
    </div>

    <div class="col-12 lg:col-10">
      <div class="card">
        <div id="overlay" v-if="overlay" v-bind:style="{cursor: selectedCursor}"></div>

        <DataTable :value="tickets" :lazy="true" :paginator="true" :rows="10" v-model:filters="filters" ref="dt"
                   dataKey="id"
                   class="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                   :totalRecords="stats.count" :loading="loadingTable" @page="onPage($event)" @sort="onSort($event)"
                   responsiveLayout="scroll"
                   @row-select="onRowSelect" @row-unselect="onRowUnselect"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]" #
                   currentPageReportTemplate="{first} al {last} de {totalRecords}">

          <ColumnGroup type="header">
            <Row>
              <Column header="Ticket"
                      :sortable="true"
                      field="tticket"
                      bodyStyle="text-align: left; width: 120px !important;">
                <template #body="{ data}">
                  <Button
                      class="t-tickets"
                      type="button"
                      :style="getColour(data.mmonto_aceptado)"
                      :label="data.tticket"
                      @click="openViewTicketDialog(data)"
                  />
                </template>
              </Column>

              <Column header="Fecha"
                      :sortable="true"
                      field="hora"
                      bodyStyle="text-align: right;">
                <template #body="{data}">
                  {{ formatDate(data.dtransaccion) }}
                </template>
                <template #footer>
                  Total:
                </template>
              </Column>

              <Column header="Monto"
                      :sortable="true"
                      field="mmonto_aceptado"
                      bodyStyle="text-align: right"
                      footerStyle="text-align: right">
                <template #body="{data}">
                  {{ formatCurrency(data.mmonto_aceptado) }}
                </template>
                <template #footer>
                  {{ formatCurrency(this.stats.mmonto_aceptado) }}
                </template>
              </Column>

              <Column header="Premio" :sortable="true" field="mmonto_premio" bodyStyle="text-align: right;"
                      footerStyle="text-align: right">>
                <template #body="{data}">
                  {{ formatCurrency(data.mmonto_premio) }}
                </template>
                <template #footer>
                  {{ stats.mmonto_premio ? formatCurrency(stats.mmonto_premio) : formatCurrency(0) }}
                </template>
              </Column>

              <Column header="Bloque" :sortable="true" field="tcomercializador" bodyStyle="text-align: left;"  v-if="identity.roleId < 2"/>
              <Column header="Banca" :sortable="true" field="tbanca" bodyStyle="text-align: left;" v-if="identity.roleId < 3"/>
              <Column header="Distribuidor" :sortable="true" field="tdistribuidor" bodyStyle="text-align: left;" v-if="identity.roleId < 4"/>
              <Column header="Punto de Venta" :sortable="true" field="tagencia" bodyStyle="text-align: left;"/>
              <Column header="Estatus" :sortable="true" field="tstatus" bodyStyle="text-align: left;">
                <template #body="{data}">
                  <div :class="statusClass(data)">
                    {{ getStatus(data) }}
                  </div>
                </template>
              </Column>

            </Row>
          </ColumnGroup>
        </DataTable>
      </div>
    </div>
  </div>

  <ViewDialog
      :show="displayTicket"
      :ticket="ticket"
      :lista="lista"
      @close="closeViewTicketDialog"/>

</template>

<script>
import TicketService from "@/service/backend/TicketService";
import generalMixin from '@/mixins/generalMixin.js'
import Filter from "@/components/Filters/Filter";
import FilterAnalyst from "@/components/Filters/FilterAnalyst";
import ViewDialog from "@/components/Tickets/Dialogs/ViewDialog";


export default {
  mixins: [generalMixin],
  components: {
    'ViewDialog': ViewDialog,
    'Filter': Filter,
    'FilterAnalyst': FilterAnalyst,
  },
  data() {
    return {
      loading1: false,
      loadingTable: false,
      tickets: [],
      componentKey: 0,
      selectedDate: new Date(),
      max: 0,
      winnerTicketDetail: [],
      openWinnerDialog: false,
      header: null,
      total: 0,
      filters: [],
      selectedDistribuidor: null,
      vuex: [],
      selectedGame: false,
      selectedLista: false,
      games: [
        {name: 'Lotería', code: 'lottery'},
        {name: 'Animalitos', code: 'animal',}
      ],
      stats: {count: 0, sum: 0},
      page: 'sold',
      override: null,
      identity: this.getIdentity(),
      totalRecords: 0,
      lazyParams: {},
      displayTicket: false,
      ticket: null,
      lista: null,
      category: null,
    }
  },
  saleService: null,
  created() {
    this.ticketService = new TicketService();
    this.$primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubrer", "Noviembre", "Diciembre"];
    this.$primevue.config.locale.dayNamesMin = ["Do", "Lu", "Ma", "Mi", "Ju", "Vr", "Sa"];
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      options: this.filters,
    };
  },
  methods: {
    async loadLazyData(filters) {
      this.loadingTable = true;
      this.lazyParams.lista = null; // Get all products
      this.lazyParams.options = filters ? filters : this.lazyParams.options;
      this.lazyParams.options.identity = this.identity;
      this.lazyParams.identity = this.identity;
      this.lazyParams.options.cstatus_transaccion = '0,2,4'
      this.category = filters ? filters.category : null;

      const data = await this.ticketService.getTableDataLazy(this.lazyParams)

      this.tickets = data.rows;
      this.stats = data.stats;
      this.max = data.stats.max_aceptado;
      this.totalRecords = data.stats.count;
      this.loadingTable = false;
    },
    async openViewTicketDialog(params) {
      params.game = this.category ? 'animal' : 'lottery';
      this.overlay = true;
      this.ticket = await this.ticketService.getTicket(params);
      this.displayTicket = true;
    },
    closeViewTicketDialog() {
      this.displayTicket = false;
      this.overlay = false;
    },
    onPage(event) {
      this.loadingTable = true;
      const options = this.lazyParams.options;
      this.lazyParams = event;
      this.lazyParams.options = options;
      this.loadLazyData();
    },
    onSort(event) {
      this.loadingTable = true;
      const options = this.lazyParams.options;
      this.lazyParams = event;
      this.lazyParams.options = options;
      this.loadLazyData();
    },
    computed: {
      computedVisible: {
        get() {
          return this.show
        },
        set() {
          this.$emit('close')
        }
      },
    },
  },
}
</script>
<style>
.block_container {
  display: flex;
  justify-content: space-between;
}

.t-tickets {
  margin-bottom: 5px;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.producto {
  font-size: x-small;
  margin: 2px 0 2px 0;
  /*background-color: #004a49;*/
  /*border-color: #004a49;*/
  width: 140px;
  /*color: white;*/
}

.t-tickets {
  width: 100%;
  font-weight: bold;
}
</style>