import dotenv from 'dotenv';
import axios from "axios";

class BaseService {
    constructor() {
        // Get environment variables
        dotenv.config()
        this.axios = axios;

        // Select currency API
        if (localStorage.getItem('currency') === 'USD') {
            this.axios.defaults.baseURL = (process.env.NODE_ENV !== 'development')
                ? "https://api-usd.banklot.net/api"
                : "http://localhost:2000/api"
        } else {
            this.axios.defaults.baseURL = (process.env.NODE_ENV !== 'development')
                ? "https://api.banklot.net/api"
                : "http://localhost:3000/api"
        }

        // this.axios.defaults.headers.common['Authorization'] = store.getState().session.token;
        this.axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
    }

}

export default BaseService;
