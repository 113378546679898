<template>
<!--  <pre>{{selectedPaymentProfile && selectedPaymentProfile}}</pre>-->
  <form @submit.prevent="confirmSaveData" class="p-fluid" autocomplete="off">
    <div class="flex justify-content-center">


      <Accordion style="width: 100%;" :multiple="true" :activeIndex="0">
        <AccordionTab header="Lotería">

          <Dropdown v-model="selectedPaymentProfile"
                    :options="paymentProfiles"
                    optionLabel="tperfil_pago_premios"
                    style="width: 100%; margin-bottom: 10px;"
                    :onChange="getData"
                    placeholder="Seleccionar perfil de pago"/>

          <DataTable :value="percentages && percentages.loteria"
                     editMode="row" dataKey="id"
                     v-model:editingRows="editingRows"
                     @row-edit-save="onRowEditSaveLoteria"
                     responsiveLayout="scroll"
                     :loading="loadingTable"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">

            <Column field="tlista" header="Concepto" style="text-align: left;"/>
            <Column field="value" header="Porcentaje" :sortable="true" style="text-align: center;">
              <template #body="{data}">
                {{ formatNumber(data.value) }}%
              </template>
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" suffix="%" :min="0" :max="40" showButtons/>
              </template>
            </Column>
            <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
          </DataTable>
        </AccordionTab>

        <AccordionTab header="Animalitos">
          <DataTable :value="percentages && percentages.animalitos" editMode="row" dataKey="id"
                     v-model:editingRows="editingRows"
                     @row-edit-save="onRowEditSaveAnimalitos" responsiveLayout="scroll"
                     :loading="loadingTable"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">
            <Column field="tlista" header="Concepto" style="text-align: left;"/>
            <Column field="value" header="Porcentaje" style="text-align: center;">
              <template #body="{data}">
                {{ formatNumber(data.value) }}%
              </template>
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" suffix="%" :min="0" :max="40" showButtons/>
              </template>
            </Column>
            <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
          </DataTable>
        </AccordionTab>
      </Accordion>
    </div>

    <div class="flex justify-content-end" style="margin-top: 10px;">
      <Button
          class="p-button-raised" icon="pi pi-save"
          type="submit"
          :disabled="!bDirty"
      />
    </div>
  </form>

  <Dialog v-model:visible="savePercentagesDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>¿Está seguro que quiere editar los porcentajes seleccionados?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="savePercentagesDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="saveData"/>
    </template>
  </Dialog>
</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import BankingService from "@/service/backend/BankingService";

export default {
  name: "Tab2",
  mixins: [generalMixin],
  created() {
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
    this.bankingService = new BankingService;
  },
  props: {
    banking: Object,
  },
  data() {
    return {
      percentages: null,
      paymentProfiles: null,
      editingRows: [],
      loadingTable: false,
      selectedPaymentProfile: null,
      bDirty: false,
      active: 0,
      savePercentagesDialog: false,
    }
  },
  async mounted() {
    this.paymentProfiles = await this.bankingService.getPaymentProfile({
      id_comercializador: this.banking.id_comercializador,
      id_banca: this.banking.id_banca,
    });
    this.selectedPaymentProfile = this.paymentProfiles[0];
    await this.getData();
  },
  methods: {
    async getData() {
      this.loadingTable = true;
      this.percentages = await this.bankingService.getBankingPercentages({
        id_comercializador: this.banking.id_comercializador,
        id_banca: this.banking.id_banca,
        id_perfil_premiosxprocentajes: this.selectedPaymentProfile.id_perfil,
        id_psxcmxban: this.banking.id_psxcmxban,
      });
      this.loadingTable = false;
    },
    onRowEditSaveLoteria(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.percentages.loteria[index] = newData;
    },
    onRowEditSaveAnimalitos(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.percentages.animalitos[index] = newData;
    },
    confirmSaveData() {
      this.savePercentagesDialog = true;
    },
    async saveData() {
      let loteria = [];
      this.percentages.loteria.forEach((item) => {
        loteria[item.code] = Number(item.value)
      });
      let animalitos = [];
      this.percentages.animalitos.forEach((item) => {
        animalitos[item.code] = Number(item.value)
      });
      const response = await this.bankingService.updateBankingPercentages({
        // Loteria
        id_psxcmxban: this.banking.id_psxcmxban,
        id_perfil_premiosxporcentajes: this.selectedPaymentProfile.id_perfil,
        porcentaje_terminal: loteria.terminal,
        porcentaje_triple: loteria.triple,
        porcentaje_terminal_s: loteria.terminal_signo,
        porcentaje_triple_s: loteria.triple_signo,
        porcentaje_participacion: loteria.participacion,
        porcentaje_regalia: loteria.regalia,
        // Animalitos
        id_prestador_servicio: 100,
        id_comercializador: this.banking.id_comercializador,
        id_banca: this.banking.id_banca,
        porcentaje_comision_animalitos: animalitos.comision,
        porcentaje_participacion_animalitos: animalitos.participacion,
        porcentaje_regalia_animalitos: animalitos.regalia,
        id_perfil_animalitos: 1,
      });

      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `No se pudo editar los porcentajes de la banca ${this.banking.tbanca}| ` + response.error,
          life: 10000
        });
        this.overlay = false;
      } else {
        this.$toast.add({
          severity: 'success',
          summary: '',
          detail: `Porcentajes de banca ${this.banking.tbanca} editados`,
          life: 3000
        });
        this.$emit('close', close);
      }

    }

  }
}
</script>

<style scoped lang="scss">

::v-deep( .p-accordion-header) {
  margin: 5px;
}

</style>