<template>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>


  <Card>
    <template #header>

    </template>
    <template #title>
      Porcentajes
    </template>
    <template v-slot:subtitle>
      Añadir comercializador
    </template>
    <template #content>

      <!--        <form @submit.prevent="saveData" class="p-fluid" autocomplete="off" style="width: 70%;">-->
      <div class="flex justify-content-center" style="margin-bottom: 10px;">
        <div style="width: 100%">
          <Panel>
            <template #header>
              <strong>Loteria</strong>
            </template>


              <div class="field">
                <label for="firstname1">Seis</label>
                <InputNumber v-model="seis" showButtons suffix="%" style="width:100%"/>
              </div>
              <div class="field">
                <label for="firstname1">Siete</label>
                <InputNumber v-model="siete" showButtons suffix="%" style="width:100%"/>
              </div>

            <div class="field">
              <label for="firstname1">Ocho</label>
              <InputNumber v-model="ocho" showButtons suffix="%" style="width:100%"/>
            </div>
            <div class="field">
              <label for="firstname1">Participación</label>
              <InputNumber v-model="participacion" showButtons suffix="%" style="width:100%"/>
            </div>
            <div class="field">
              <label for="firstname1">Regalía</label>
              <InputNumber v-model="regalia" showButtons suffix="%" style="width:100%"/>
            </div>

          </Panel>
        </div>
      </div>
      <div class="flex justify-content-center">
        <div style="width: 100%">
          <Panel>
            <template #header>
              <strong>Animalitos</strong>
            </template>
            <div class="field">
              <label for="firstname1">Comision</label>
              <InputNumber v-model="comision_animalitos" showButtons suffix="%" style="width:100%"/>
            </div>
            <div class="field">
              <label for="firstname1">Participación</label>
              <InputNumber v-model="participacion_animalitos" showButtons suffix="%" style="width:100%"/>
            </div>
            <div class="field">
              <label for="firstname1">Regalía</label>
              <InputNumber v-model="regalia_animalitos" showButtons suffix="%" style="width:100%"/>
            </div>
          </Panel>
        </div>
      </div>
      <!--        </form>-->
<!--      <div class="flex justify-content-end" style="margin-top: 10px;">-->
<!--        <Button-->
<!--            class="p-button-raised" icon="pi pi-save"-->
<!--            :onClick="saveData"-->
<!--            :disabled="!bDirty"-->
<!--        />-->
<!--      </div>-->
    </template>
    <template #footer>

      <div class="grid grid-nogutter justify-content-between">
                <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left"/>
                <Button label="Siguiente"
                        @click="nextPage()"
                        icon="pi pi-angle-right"
                        iconPos="right"
                />
      </div>
    </template>
  </Card>
  <Toast/>
</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import MainService from "@/service/backend/MainService";
import BlockService from "@/service/backend/BlockService";

export default {
  name: "Step4",
  mixins: [generalMixin],
  components: {},

  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/bloques/agregar-1'
        },
        {
          label: 'Cadena de comercialización',
          to: '/bloques/agregar-2'
        },
        {
          label: 'Porcentajes',
          to: '/bloques/agregar-3'
        },
      ],
      pageIndex: 2,
      paymentProfiles: null,
      selectedPaymentProfile: null,
      percentages: null,
      loadingTable: false,
      editingRows: [],
      bDirty: false,
      idBlock: null,
      active: 0,
      // Loteria
      seis: 0,
      siete: 0,
      ocho: 0,
      participacion: 0,
      regalia: 0,
      // Animalitos
      comision_animalitos: 0,
      participacion_animalitos: 0,
      regalia_animalitos: 0,
    }
  },
  props: {},
  created() {
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
    this.mainService = new MainService;
    this.blockService = new BlockService;
  },
  async mounted() {
    await this.loadPaymentProfiles();
    await this.loadData();
  },
  methods: {
    async loadPaymentProfiles() {
      this.paymentProfiles = await this.blockService.getPaymentProfile({
        id_comercializador: this.$store.getters.getBlock.id_comercializador,
      });
      this.selectedPaymentProfile = this.paymentProfiles[0];
    },
    async loadData() {
      this.percentages = await this.blockService.getBlockPercentages({
        id_comercializador: this.$store.getters.getBlock.id_comercializador,
        id_perfil_premiosxporcentajes: this.selectedPaymentProfile.id_perfil,
        id_psxcmxbanxdis: this.$store.getters.getBlock.id_psxcmxbanxdis,
      });
    },
    async createBlock() {
      let response;
      try {
        response = await this.blockService.createBlock({
          tcomercializador: this.$store.getters.getBlock.blockName,
          id_comercializador: this.$store.getters.getBlock.id_comercializador,
          id_estado: this.$store.getters.getBlock.id_estado,
          id_municipio: this.$store.getters.getBlock.id_municipio,
          trif_agencia: '',
          tcorreo_electronico: '',
          // Loteria
          seis: this.seis,
          siete: this.siete,
          ocho: this.ocho,
          participacion: this.participacion,
          regalia: this.regalia,
          // Animalitos
          comision_animalitos: this.comision_animalitos,
          participacion_animalitos: this.participacion_animalitos,
          regalia_animalitos: this.regalia_animalitos,
        });
        this.$toast.add({
          severity: 'success',
          summary: '',
          detail: `Comercializador ${this.$store.getters.getBlock.blockName} creado `,
          life: 3000
        });
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `No se pudo crear el comercializador ${this.$store.getters.getBlock.blockName}| ` + e.message,
          life: 10000
        });
        this.overlay = false;
        return false;
      }

      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `No se pudo crear el comercializador ${this.$store.getters.getBlock.blockName}| ` + response.error,
          life: 10000
        });
        this.overlay = false;
        return false;
      } else {
        this.idBlock = response[0].fn_inserta_comercializador;

        this.$store.commit('setBlock', {
              blockName: this.$store.getters.getBlock.blockName,
              id_comercializador: this.$store.getters.getBlock.id_comercializador,
              id_estado: this.selectedEstado,
              id_municipio: this.selectedMunicipio,
              id_psxcmxbanxdis: this.id_psxcmxbanxdis,
            }
        );
        return true;
      }
    },
    async nextPage() {
      if (await this.createBlock()) {
        this.$router.push({name: 'bloques',});
      }
    },
    prevPage() {
      this.$router.push('/bloques/agregar-2');
    },
  }
}
</script>

<style scoped lang="scss">

.filterItem {
  margin: 20px 0 5px 0;
}

.content {
  width: 80%;
}

.p-float-label {
  width: 100%;
}
</style>