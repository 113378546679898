<template>

  <Toast/>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>

  <Card style="border-radius:12px;">
    <template v-slot:title>
      Cadena de Comercialización
    </template>
    <template v-slot:subtitle>
      Añadir banca
    </template>
    <template #content>
      <div class="filters">
        <!-- Comercializador -->
        <Dropdown v-model="selectedComercializador" :options="comercializadores"
                  optionLabel="tcomercializador" optionValue="id_comercializador"
                  placeholder="Seleccionar Bloque" class="filterItem" :loading="loadingComercializador"
                  :showClear="true"
                  v-if="Number(roleId) <= 1"
                  style="width: 100%;"
                  :filter="true"/>
        <br>
      </div>

    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-between">
        <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left"/>
        <Button label="Siguiente"
                @click="nextPage()"
                icon="pi pi-angle-right"
                iconPos="right"
                :disabled="this.selectedComercializador ? disabled : ''"
        />
      </div>
    </template>
  </Card>

  <router-view v-slot="{Component}"
               @prevPage="prevPage($event)"
               @nextPage="nextPage($event)">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>

</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import {useVuelidate} from "@vuelidate/core";

export default {
  name: "Step2",
  mixins: [generalMixin],
  setup: () => ({v$: useVuelidate()}),
  created() {
    this.mainService = new MainService();
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
    this.theDate = this.getYesterdayString();
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
  },
  mounted() {
    this.comercializadores = this.refreshComercializador();
    this.bancas = this.refreshBanca();
    this.theDate = this.getYesterdayString();

    this.selectedComercializador = this.$store.getters.getBanking.id_comercializador;
    this.selectedBanca = this.$store.getters.getBanking.id_banca;
    // It's a block
    if (Number(this.identity.roleId) <= 2) {
      this.selectedComercializador = this.identity.entityId;
    }
  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/bancas/agregar-1'
        },
        {
          label: 'Cadena de comercialización',
          to: '/bancas/agregar-2'
        },
        {
          label: 'Dirección',
          to: '/bancas/agregar-3'
        },
      ],
      selectedComercializador: null,
      selectedBanca: null,

      comercializadores: null,
      bancas: null,

      loadingComercializador: false,
      loadingBanca: false,

      pageIndex: 0,
      roleId: null,
      params: null,
    }
  },
  methods: {
    async refreshComercializador() {
      this.loading = true;
      this.selectedComercializador = null;
      this.comercializadores = await this.mainService.getComercializador({
        fecha: this.theDate,
        entity_id: this.entityId,
        hide_external: true,
        // id_comercializador: this.selectedComercializador,
        page: 'agencies',
      });
      this.loading = false;
    },
    async refreshBanca() {
      this.loading = true;
      this.selectedBanca = null;
      this.bancas = await this.mainService.getBanca({
        fecha: this.theDate,
        entity_id: this.entityId,
        id_comercializador: this.selectedComercializador,
        page: 'agencies',
      });
      this.loading = false;
    },
    nextPage() {
      this.$store.commit('setBanking', {
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        bankingName: this.$store.getters.getBanking.bankingName,
        id_estado: this.$store.getters.getBanking.id_estado,
        id_municipio: this.$store.getters.getBanking.id_municipio,
      });
      this.$router.push({name: 'bancas/agregar-3',});
    },
    prevPage() {
      this.$store.commit('setBanking', {
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        bankingName: this.$store.getters.getBanking.bankingName,
        id_estado: this.$store.getters.getBanking.id_estado,
        id_municipio: this.$store.getters.getBanking.id_municipio,
      });
      this.$router.push('/bancas/agregar-1');
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 20px 0 5px 0;
}

</style>