<template>
  <div id="overlay" v-if="overlay"></div>
  <div>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <div class="block_container">
            <div class="bloc1"><h3>Agencias {{ totalRecords && '(' + totalRecords + ')' }}</h3></div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-2">
        <div class="card p-fluid" id="number-container">
          <!--     Filter goes here     -->
          <Filter
              @get-data="getData"
              page="agencies"
          />
        </div>
      </div>
      <div class="col-12 lg:col-10">
        <div class="card">
          <Toolbar class="mb-4">
            <template #start>
              <Button label="" icon="pi pi-plus" class="p-button-success mr-2" @click="createAgency"/>
            </template>
            <template #end>

              <Button label="" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                      :disabled="!selectedAgencies || !selectedAgencies.length"/>

            </template>
          </Toolbar>
          <DataTable :value="agencies" :lazy="true" :paginator="true" :rows="10"
                     v-model:filters="filters" ref="dt"
                     dataKey="id"
                     :totalRecords="totalRecords"
                     responsiveLayout="scroll"
                     :loading="loadingTable" @page="onPage($event)"
                     @sort="onSort($event)"
                     @filter="onFilter($event)"
                     v-model:selection="selectedAgencies"
                     :selectAll="selectAll"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table"
                     showGridlines
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     :rowsPerPageOptions="[5,10,25,50]"
                     currentPageReportTemplate="{first} al {last} de {totalRecords}"
                     @select-all-change="onSelectAllChange" @row-select="onRowSelect" @row-unselect="onRowUnselect">

            <template #header>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <h5 class="mb-2 md:m-0 p-as-md-center">Agencias</h5>
                <span class="p-input-icon-left">
                                      <i class="pi pi-search"/>
                                      <InputText v-model="searchFilters['global'].value" placeholder="Buscar..."
                                                 v-on:keyup.enter="onFilter"/>
                                  </span>
              </div>
            </template>
            <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

            <Column field="cstatus_agencia" header="Estatus" :sortable="true"
                    bodyStyle="text-align: center; white-space: nowrap !important;">
              <template #body="{data}">
                <span :class="'status-badge '+getEntityStatusClass(data.cstatus_agencia)">
                  {{ getEntityStatus(data.cstatus_agencia).toUpperCase() }}
                </span>
              </template>
            </Column>


            <Column field="tagencia" header="Nombre" :sortable="true" filterMatchMode="startsWith" ref="id_agencia"/>
            <Column field="trif_agencia" header="RIF" :sortable="true"></Column>
            <Column field="tcomercializador" header="Bloque" :sortable="true" bodyStyle="text-align: left;" v-if="identity.roleId < 2"/>
            <Column field="tbanca" header="Banca" :sortable="true" bodyStyle="text-align: left;" v-if="identity.roleId < 3"/>
            <Column field="tdistribuidor" header="Distribuidor" :sortable="true" bodyStyle="text-align: left;" v-if="identity.roleId < 4"/>
            <Column field="testado" header="Estado" :sortable="true" bodyStyle="text-align: left;"/>
            <Column field="tmunicipio" header="Municipio" :sortable="true" bodyStyle="text-align: left;"/>
            <Column field="dcreacion" header="Fecha de creación" :sortable="true" style="min-width:4rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ formatDate(data.dcreacion) }}
              </template>
            </Column>

            <Column field="dborrado_virtual" header="Fecha de borrado" :sortable="true" bodyStyle="text-align: center;"
                    v-if=" filters && filters.displayDeleted">
              <template #body="{data}">
                {{ data.dborrado_virtual && formatDate(data.dborrado_virtual) }}
              </template>
            </Column>
            <Column field="cstatus_agencia" header="Acción" style="width:10px;">
              <template #body="props">
                <div class="flex justify-content-between">
                  <span class="p-row-editor-init-icon pi pi-fw pi-lock edit-icon"
                        v-tooltip.bottom="'Bloquear'"
                        v-if="props.data.cstatus_agencia!==4"
                        v-bind:class="{
                                    'locked': props.data.cstatus_agencia === 2,
                                    'unlocked': props.data.cstatus_agencia === 1,
                                    'fully-locked': props.data.cstatus_agencia === 3
                                  }"
                        @click="confirmBlockAgency(props.data)"></span>
                  <span class="p-row-editor-init-icon pi pi-fw pi-pencil edit-icon"
                        v-tooltip.bottom="'Editar'"
                        @click="editAgency(props.data)"></span>
                </div>
              </template>
            </Column>
          </DataTable>

        </div>
      </div>
    </div>
  </div>

  <EditAgencyDialog
      :agency="agency"
      :agencyDetails="agencyDetails"
      :show="displayEditAgency"
      ref="editAgencyDialog"
      @new-user="pushNewAgency"
      @close="closeAgencyDialog"/>

  <Dialog v-model:visible="blockAgencyDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="agency">¿{{
          this.agency.cstatus_agencia === 2 ? 'Desbloquear' : 'Bloquear'
        }} agencia <b>{{ agency && agency.tagencia }}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="blockAgencyDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="blockAgency(agency)"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteAgenciesDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>¿Borrar la(s) agencia(s) seleccionada(s)?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteAgenciesDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelectedAgencies"/>
    </template>
  </Dialog>

  <Toast/>

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import Filter from "@/components/Filters/Filter";
import AgencyService from "@/service/backend/AgencyService";
import {FilterMatchMode} from "primevue/api";
import EditAgencyDialog from "@/components/Settings/Agencies/Dialog/EditAgencyDialog";

export default {
  components: {
    'Filter': Filter,
    'EditAgencyDialog': EditAgencyDialog,
  },
  mixins: [generalMixin],
  data() {
    return {
      agencies: null,
      totalRecords: null,
      identity: null,
      loadingTable: false,
      searchFilters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id_agencia': {value: '', matchMode: 'contains'},
        'tagencia': {value: '', matchMode: 'contains'},
        'dcreacion': {value: '', matchMode: 'contains'},
      },
      filterData: null,
      displayEditAgency: false,
      agency: null,
      agencyDetails: null,
      overlay: false,

      deleteAgenciesDialog: null,
      selectedAgencies: null,
      selectAll: null,

      blockAgencyDialog: false,
      deleteAgencyDialog: false,

    }
  },
  userService: null,
  created() {
    this.agencyService = new AgencyService;
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  async mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      searchFilters: this.searchFilters
    };
    await this.getData();
  },
  methods: {
    async getData(filters) {
      this.selectedAgencies = null;
      if (filters) {
        filters.lista = null;
        this.filterData = filters;
      }
      this.lazyParams.identity = this.identity;
      this.lazyParams.filters = this.filterData;
      let payload = Object.assign(this.lazyParams);
      this.loadingTable = true;
      const data = await this.agencyService.getAll(payload);
      this.agencies = data.rows;
      this.totalRecords = Number(data.total);
      this.loadingTable = false;
    },
    onPage(event) {
      this.lazyParams = event;
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onSort(event) {
      this.lazyParams = event;
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onFilter() {
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onSelectAllChange(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.agencyService.getAll().then(data => {
          this.selectAll = true;
          this.selectedAgencies = data.agencies;
        });
      } else {
        this.selectAll = false;
        this.selectedAgencies = [];
      }
    },
    onRowSelect() {
      this.selectAll = this.selectedAgencies.length === this.totalRecords;
    },
    onRowUnselect() {
      this.selectedAgencies = false;
    },
    createAgency() {
      this.$store.commit('setAgency', {});
      this.$router.push('/agencias/agregar-1');
    },
    async editAgency(agency) {
      this.overlay = true;
      this.agency = {...agency};
      this.agencyDetails = await this.agencyService.getDetails({id_agencia: agency.id_agencia});
      this.displayEditAgency = true;
    },
    closeAgencyDialog() {
      this.getData();
      this.displayEditAgency = false;
      this.overlay = false;
    },
    pushNewAgency(value) {
      this.agencies.push(value);
      this.closeAgencyDialog();
    },
    confirmDeleteSelected() {
      this.deleteAgenciesDialog = true;
    },
    async deleteSelectedAgencies() {
      // this.loadingTable = true;
      let idsToDelete = [];
      this.selectedAgencies.forEach((item) => {
        idsToDelete.push(item.id)
      });
      this.deleteAgenciesDialog = false
      const response = await this.agencyService.deleteAgency({idsToDelete: idsToDelete.toString()});
      this.agencies = this.agencies.filter(val => !this.selectedAgencies.includes(val));

      this.selectedAgencies = null
      // this.loadingTable = false;
      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'Agencia(s) no eliminada(s) | ' + response.error,
          life: 10000
        });
      } else {
        this.$toast.add({severity: 'success', summary: '', detail: 'Agencia(s) eliminada(s)', life: 3000});
      }


    },
    confirmBlockAgency(agency) {
      this.agency = agency;
      this.blockAgencyDialog = true;
    },
    async blockAgency() {
      this.loadingTable = true;
      this.blockAgencyDialog = false;

      const response = await this.agencyService.blockAgency({
        id_agencia: this.agency.id_agencia,
        cstatus_agencia: this.agency.cstatus_agencia,
      });
      await this.getData();
      this.loadingTable = false;

      const preaction = this.agency.cstatus_agencia === 2 ? 'des' : '';
      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `Agencia no pudo ser ${preaction}bloqueada | ${response.error}`,
          life: 10000
        });
      } else {
        this.$toast.add({severity: 'success', summary: '', detail: `Agencia ${preaction}bloqueada`, life: 3000});
      }

    }

  },

}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.user-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .user-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

#role, #status {
  margin-top: 10px;
}

.field {
  padding: 0 20px 0 20px;
}

.fully-locked {
  //background-color: #db3636;
  border-color: #db3636;
  color: #db3636;
}

.locked {
  //background-color: #ffc107;
  border-color: #ffc107;
  color: #ffc107;
}

.unlocked {
  //background-color: var(--surface-400);
  border-color: var(--surface-400);
  //color: var(--green-banklot)
}

.normal {
  background-color: var(--surface-400);
  border-color: var(--surface-400);
  color: var(--green-banklot)
}

.status-badge {
  //width: 86px; //max-content;'
}

.edit-icon {
  margin: 10px;
}

</style>