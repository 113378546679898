<template>
  <div>
    <Toast/>
    <div class="card">
      <Steps :model="items" :readonly="true"/>
    </div>
    <Card>
      <template #header>
      </template>
      <template #title>
        Dirección
      </template>
      <template v-slot:subtitle>
        Añadir comercializador
      </template>
      <template #content>
        <!--         Estado-->
        <Dropdown v-model="selectedEstado" :options="estados"
                  optionLabel="testado" optionValue="id_estado"
                  placeholder="Seleccionar Estado" class="filterItem" :loading="loadingEstado"
                  :showClear="true"
                  style="width: 100%"
                  :onChange="refreshMunicipios"
                  :filter="true"/>
        <!--        Municipio-->
        <Dropdown v-model="selectedMunicipio" :options="municipios"
                  optionLabel="tmunicipio" optionValue="id_municipio"
                  placeholder="Seleccionar Municipio" class="filterItem" :loading="loadingMunicipio"
                  :showClear="true"
                  v-if="selectedEstado"
                  style="width: 100%"
                  :filter="true"/>
      </template>
      <template #footer>
        <div class="grid grid-nogutter justify-content-between">
          <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left"/>
          <Button label="Siguiente"
                  @click="nextPage()"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  :disabled="this.selectedMunicipio ? disabled : ''"/>
        </div>
      </template>
    </Card>

    <router-view v-slot="{Component}" @prevPage="prevPage($event)" @nextPage="nextPage($event)">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>
  </div>
  <Toast/>
</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import BlockService from "@/service/backend/BlockService";

export default {
  name: "Step3",
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService;
    this.blockService = new BlockService;
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
  },
  mounted() {
    this.refreshEstados();
    this.selectedEstado = this.$store.getters.getBlock.id_estado;
    this.refreshMunicipios();
    this.selectedMunicipio = this.$store.getters.getBlock.id_municipio;
  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/bloques/agregar-1'
        },
        {
          label: 'Dirección',
          to: '/bloques/agregar-2'
        },
        {
          label: 'Porcentajes',
          to: '/bloques/agregar-3'
        }
      ],
      selectedEstado: null,
      selectedMunicipio: null,
      estados: null,
      municipios: null,
      loadingEstado: false,
      loadingMunicipio: false,
      params: null,
      pageIndex: 1,
      id_psxcmxbanxdis: null,
    }
  },
  methods: {
    async refreshEstados() {
      this.municipios = null;
      this.estados = await this.mainService.getEstados();
    },
    async refreshMunicipios() {
      if (this.selectedEstado) {
        this.municipios = await this.mainService.getMunicipios({id_estado: this.selectedEstado});
      }
    },
    async nextPage() {
      this.$store.commit('setBlock', {
        blockName: this.$store.getters.getBlock.blockName,
        id_comercializador: this.$store.getters.getBlock.id_comercializador,
        id_estado: this.selectedEstado,
        id_municipio: this.selectedMunicipio,
        id_psxcmxbanxdis: this.id_psxcmxbanxdis,
      });
      this.$router.push({name: 'bloques/agregar-3',});
    },
    prevPage() {
      this.$store.commit('setBlock', {
        blockName: this.$store.getters.getBlock.blockName,
        id_comercializador: this.$store.getters.getBlock.id_comercializador,
        id_estado: this.selectedEstado,
        id_municipio: this.selectedMunicipio,
        id_psxcmxbanxdis: this.id_psxcmxbanxdis,
      });
      this.$router.push('/bloques/agregar-2');
    },
  }
}
</script>

<style scoped lang="scss">
.filterItem {
  margin: 20px 0 5px 0;
}

</style>