<template>
  <div class="main">
    <div class="field-title">{{ title }}</div>
    <Inplace :closable="true">
      <template #display>
        {{ content || 'Click para editar' }}
      </template>
      <template #content v-if="!disabled">
        <InputText v-model="content"
                   autoFocus autocomplete="off"
                   :class="{'p-invalid':vv.$errors[0] && !vv.$errors[0].$pending}"/>
        <label for=name :class="{'p-error':vv.$errors}"/>
      </template>
    </Inplace>
    <small v-if="vv.$errors" class="p-error">{{ vv.$errors[0] && vv.$errors[0].$message }}</small>
  </div>
</template>

<script>
import {ref} from 'vue'
import useVuelidate from '@vuelidate/core'
import {email, helpers, required} from '@vuelidate/validators'

export default {
  name: 'EditLabel',
  props: {
    title: {
      type: String,
      default: ''
    },
    input: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    email: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  setup(props) {
    const content = ref(props.input);
    const mandatoryField = 'Campo obligatorio';
    const emailField = 'Campo debe ser un correo electrónico';

    let rules = {content: {$autoDirty: true}};
    if (props.required) {
      rules.content.required = helpers.withMessage(mandatoryField, required);
    }
    if (props.email) {
      rules.content.email = helpers.withMessage(emailField, email);
    }

    const vv = useVuelidate(rules, {content});
    return {content, vv};

  }
}
</script>

<style lang="css" scoped>
.main {
  padding: 10px;
}

.field-title {
  color: var(--green-banklot);
  font-weight: bold;
  margin-bottom: 10px;
}
</style>