<template>
  <div id="overlay" v-if="overlay"></div>
  <div>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <div class="block_container">
            <div class="bloc1"><h3>Distribuidores {{ totalRecords && '(' + totalRecords + ')' }}</h3></div> 
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-2">
        <div class="card p-fluid" id="number-container">
          <!--     Filter goes here     -->
          <Filter
              @get-data="getData"
              page="distributors"
          />
        </div>
      </div>
      <div class="col-12 lg:col-10">
        <div class="card">
          <Toolbar class="mb-4">
            <template #start>
              <Button label="" icon="pi pi-plus" class="p-button-success mr-2" @click="createDistributor"/>
            </template>
            <template #end>
              <Button label="" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                      :disabled="!selectedDistributors || !selectedDistributors.length"/>

            </template>
          </Toolbar>
          <DataTable :value="distributors" :lazy="true" :paginator="true" :rows="10"
                     v-model:filters="filters" ref="dt"
                     dataKey="id"
                     :totalRecords="totalRecords"
                     :loading="loadingTable" @page="onPage($event)"
                     @sort="onSort($event)"
                     @filter="onFilter($event)"
                     v-model:selection="selectedDistributors"
                     responsiveLayout="scroll"
                     :selectAll="selectAll"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table"
                     showGridlines
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     :rowsPerPageOptions="[5,10,25,50]"
                     currentPageReportTemplate="{first} al {last} de {totalRecords}"
                     @select-all-change="onSelectAllChange" @row-select="onRowSelect" @row-unselect="onRowUnselect">

            <template #header>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <h5 class="mb-2 md:m-0 p-as-md-center">Distribuidores</h5>
                <span class="p-input-icon-left">
                                      <i class="pi pi-search"/>
                                      <InputText v-model="searchFilters['global'].value" placeholder="Buscar..."
                                                 v-on:keyup.enter="onFilter"/>
                                  </span>
              </div>
            </template>
            <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

            <Column field="cstatus_distribuidor" header="Estatus" :sortable="true"
                    bodyStyle="text-align: center; white-space: nowrap !important;">
              <template #body="{data}">
                <span :class="'status-badge '+getEntityStatusClass(data.cstatus_distribuidor)">
                  {{ getEntityStatus(data.cstatus_distribuidor).toUpperCase() }}
                </span>
              </template>
            </Column>


            <Column field="tdistribuidor" header="Nombre" :sortable="true" filterMatchMode="startsWith"
                    ref="id_distribuidor"/>
            <Column field="tcomercializador" header="Bloque" :sortable="true" bodyStyle="text-align: left;" v-if="identity.roleId  < 2"/>
            <Column field="tbanca" header="Banca" :sortable="true" bodyStyle="text-align: left;" v-if="identity.roleId < 3"/>
            <Column field="tdistribuidor" header="Distribuidor" :sortable="true" bodyStyle="text-align: left;" v-if="identity.roleId < 4"/>
            <Column field="id_tipo_cupo" header="Tipo de Cupo" :sortable="true" style="min-width:4rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ data.id_tipo_cupo == 1 ? 'FILTRO' : 'DINAMICO' }}
              </template>
            </Column>
            <Column field="dcreacion" header="Fecha de creación" :sortable="true" style="min-width:4rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ formatDate(data.dcreacion) }}
              </template>
            </Column>

            <Column field="dborrado_virtual" header="Fecha de borrado" :sortable="true" bodyStyle="text-align: center;"
                    v-if=" filters && filters.displayDeleted">
              <template #body="{data}">
                {{ data.dborrado_virtual && formatDate(data.dborrado_virtual) }}
              </template>
            </Column>
            <Column field="cstatus_distribuidor" header="Acción" style="width:10px;">
              <template #body="props">
                <div class="flex justify-content-between">
                  <span class="p-row-editor-init-icon pi pi-fw pi-lock edit-icon"
                        v-tooltip.bottom="'Bloquear'"
                        v-if="props.data.cstatus_distribuidor!==4"
                        v-bind:class="{
                                    'locked': props.data.cstatus_distribuidor === 2,
                                    'unlocked': props.data.cstatus_distribuidor === 1,
                                    'fully-locked': props.data.cstatus_distribuidor === 3
                                  }"
                        @click="confirmBlockDistributor(props.data)"></span>
                  <span class="p-row-editor-init-icon pi pi-fw pi-pencil edit-icon"
                        v-tooltip.bottom="'Editar'"
                        @click="editDistributor(props.data)"></span>
                </div>
              </template>
            </Column>
          </DataTable>

        </div>
      </div>
    </div>
  </div>

  <EditDistributorDialog
      :distributor="distributor"
      :distributorDetails="distributorDetails"
      :show="displayEditDistributor"
      ref="editDistributorDialog"
      @new-user="pushNewDistributor"
      @close="closeDistributorDialog"/>

  <Dialog v-model:visible="blockDistributorDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="distributor">¿{{
          this.distributor.cstatus_distribuidor === 2 ? 'Desbloquear' : 'Bloquear'
        }} distribuidor <b>{{ distributor && distributor.tdistribuidor }}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="blockDistributorDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="blockDistributor(distributor)"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteDistributorsDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>¿Borrar los distribuidores seleccionados?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDistributorsDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelectedDistributors"/>
    </template>
  </Dialog>

  <Toast/>

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import Filter from "@/components/Filters/Filter";
import DistributorService from "@/service/backend/DistributorService";
import {FilterMatchMode} from "primevue/api";
import EditDistributorDialog from "@/components/Settings/Distributors/Dialog/EditDistributorDialog";

export default {
  components: {
    'Filter': Filter,
    'EditDistributorDialog': EditDistributorDialog,
  },
  mixins: [generalMixin],
  data() {
    return {
      distributors: null,
      totalRecords: null,
      identity: null,
      loadingTable: false,
      searchFilters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id_distribuidor': {value: '', matchMode: 'contains'},
        'tdistribuidor': {value: '', matchMode: 'contains'},
        'dcreacion': {value: '', matchMode: 'contains'},
      },
      filterData: null,
      displayEditDistributor: false,
      distributor: null,
      distributorDetails: null,
      overlay: false,

      deleteDistributorsDialog: null,
      selectedDistributors: null,
      selectAll: null,

      blockDistributorDialog: false,
      deleteDistributorDialog: false,

    }
  },
  userService: null,
  created() {
    this.distributorService = new DistributorService;
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  async mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      searchFilters: this.searchFilters
    };
    await this.getData();
  },
  methods: {
    async getData(filters) {
      this.selectedDistributors = null;
      if (filters) {
        filters.lista = null;
        this.filterData = filters;
      }
      this.lazyParams.identity = this.identity;
      this.lazyParams.filters = this.filterData;
      let payload = Object.assign(this.lazyParams);
      this.loadingTable = true;
      const data = await this.distributorService.getAll(payload);
      this.distributors = data.rows;
      this.totalRecords = Number(data.total);
      this.loadingTable = false;
    },
    onPage(event) {
      this.lazyParams = event;
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onSort(event) {
      this.lazyParams = event;
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onFilter() {
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onSelectAllChange(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.distributorService.getAll().then(data => {
          this.selectAll = true;
          this.selectedDistributors = data.distributors;
        });
      } else {
        this.selectAll = false;
        this.selectedDistributors = [];
      }
    },
    onRowSelect() {
      this.selectAll = this.selectedDistributors.length === this.totalRecords;
    },
    onRowUnselect() {
      this.selectedDistributors = false;
    },
    createDistributor() {
      this.$store.commit('setDistributor', {});
      this.$router.push('/distribuidores/agregar-1');
    },
    async editDistributor(distributor) {
      this.overlay = true;
      this.distributor = {...distributor};
      this.distributorDetails = await this.distributorService.getDetails(distributor);
      this.displayEditDistributor = true;
    },
    closeDistributorDialog() {
      this.getData();
      this.displayEditDistributor = false;
      this.overlay = false;
    },
    pushNewDistributor(value) {
      this.distributors.push(value);
      this.closeDistributorDialog();
    },
    confirmDeleteSelected() {
      this.deleteDistributorsDialog = true;
    },
    async deleteSelectedDistributors() {
      // this.loadingTable = true;
      let idsToDelete = [];
      this.selectedDistributors.forEach((item) => {
        idsToDelete.push(item.id)
      });
      this.deleteDistributorsDialog = false
      const response = await this.distributorService.deleteDistributor({idsToDelete: idsToDelete.toString()});
      this.distributors = this.distributors.filter(val => !this.selectedDistributors.includes(val));

      this.selectedDistributors = null
      // this.loadingTable = false;
      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'Distribuidor(es) no eliminado(s) | ' + response.error,
          life: 10000
        });
      } else {
        this.$toast.add({severity: 'success', summary: '', detail: 'Distribuidor(es) eliminado(s)', life: 3000});
      }


    },
    confirmBlockDistributor(distributor) {
      this.distributor = distributor;
      this.blockDistributorDialog = true;
    },
    async blockDistributor() {
      this.loadingTable = true;
      this.blockDistributorDialog = false;

      const response = await this.distributorService.blockDistributor({
        id_distribuidor: this.distributor.id_distribuidor,
        cstatus_distribuidor: this.distributor.cstatus_distribuidor,
      });
      await this.getData();
      this.loadingTable = false;

      const preaction = this.distributor.cstatus_distribuidor === 2 ? 'des' : '';
      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `Agencia no pudo ser ${preaction}bloqueada | ${response.error}`,
          life: 10000
        });
      } else {
        this.$toast.add({severity: 'success', summary: '', detail: `Agencia ${preaction}bloqueada`, life: 3000});
      }

    }

  },

}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.user-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .user-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

#role, #status {
  margin-top: 10px;
}

.field {
  padding: 0 20px 0 20px;
}

.fully-locked {
  //background-color: #db3636;
  border-color: #db3636;
  color: #db3636;
}

.locked {
  //background-color: #ffc107;
  border-color: #ffc107;
  color: #ffc107;
}

.unlocked {
  //background-color: var(--surface-400);
  border-color: var(--surface-400);
  //color: var(--green-banklot)
}

.normal {
  background-color: var(--surface-400);
  border-color: var(--surface-400);
  color: var(--green-banklot)
}

.status-badge {
  //width: 86px; //max-content;'
}

.edit-icon {
  margin: 10px;
}

</style>