<template>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="block_container">
          <div class="bloc1">
            <h5>Reporte Combinado {{ report && '(' + report.length + ')' }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-2">
      <div class="card p-fluid" id="number-container">
        <!--     Filter goes here     -->
        <Filter
            @get-data="getData"
            page="report-sales"
            v-if="identity.roleId!==7"/>

        <FilterAnalyst
            @get-data="getData"
            page="report-sales"
            v-if="identity.roleId===7"/>
      </div>
    </div>

    <div class="col-12 lg:col-10">
      <div class="card">
        <DataTable :value="report"
                   class="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                   showGridlines
                   ref="dt"
                   :exportFilename="getTitle()"
                   :loading="loadingTable"
                   responsiveLayout="scroll"
                   :paginator="true"
                   :rows="10"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   currentPageReportTemplate="{first} al {last} de {totalRecords}">

          <template #header>
            <div class="table-header-container" v-if="dates">
              <div class="flex justify-content-between" v-if="report">
                <div class="header">
                  <div>
                    <h5>{{ getTitle() }}</h5>
                  </div>
                  <div class="flex justify-content-start" style="flex-wrap: wrap">
                    <div class="columns">
                      <LabelLight title="Categoría:" :content="getCategory(filters.reportCategory)"/>
                      <LabelLight title="Desde:" :content="dates && formatDateLongMoment(dates[0])" />
                      <LabelLight title="Hasta:" :content="dates && formatDateLongMoment(dates[1])" />
                    </div>
                    <div class="columns" style="width: 250px;">
                      <LabelLight title="Producto:"
                                  :content="(filters && filters.producto)?filters.producto.tproducto:'Todos'"/>
                      <LabelLight title="Perfil de Pago:"
                                  :content="(filters && filters.perfil_pago_premios)?filters.perfil_pago_premios.tperfil_pago_premios:'N/A'"/>
                    </div>
                    <div class="columns">
                      <LabelLight title="Bloque:"
                                  :content="filters && filters.comercializador  && filters.comercializador.tcomercializador"/>
                      <LabelLight title="Banca:" :content="filters && filters.banca  && filters.banca.tbanca"/>
                      <LabelLight title="Distribuidor:"
                                  :content="filters && filters.distribuidor  && filters.distribuidor.tdistribuidor"/>
                      <LabelLight title="Agencia:" :content="filters && filters.agencia  && filters.agencia.tagencia"/>
                    </div>
                  </div>

                </div>
                <div style="text-align: left">
                  <Button icon="pi pi-file-excel" @click="exportCSV($event)"/>
                </div>
              </div>
            </div>

          </template>
          <ColumnGroup type="header">
            <Row>
              <Column header="Categoría" :sortable="true" field="categoria" bodyStyle="text-align: center;"
                      headerStyle="width: 100px;">
                <template #body="{data}">
                  {{ capitalizeFirstLetter(data.categoria) }}
                </template>
              </Column>
              <Column :header="header && header.title" :sortable="true"
                      :field="header && header.field"
                      bodyStyle="text-align: left;"
                      footerStyle="text-align: right;"
                      footer="Total:">
                <template #body="{data}">
                  <div v-if="header.title==='Fecha'">{{ formatDateLong2(data[header.field]) }}</div>
                  <div v-if=" header.title!=='Fecha'">{{ data[header.field] }}</div>
                </template>
              </Column>


              <Column header="Venta" :sortable="true" field="venta"
                      bodyStyle="text-align: right;"
                      footerStyle="text-align: right;"
              >
                <template #body="{data}">
                  {{ formatCurrency(data.venta) }}
                </template>
                <template #footer>
                  {{ formatCurrency(total.venta) }}
                </template>
              </Column>

              <Column header="Premios" :sortable="true" field="premio"
                      bodyStyle="text-align: right;"
                      footerStyle="text-align: right;"
              >
                <template #body="{data}">
                  <div class="prize-number">
                    {{ formatCurrency(data.premio) }}
                  </div>

                </template>
                <template #footer>
                  <div class="prize-number">
                    {{ formatCurrency(total.premio) }}
                  </div>
                </template>
              </Column>


              <Column header="% Agencia" :sortable="true" field="com_agencia"
                      bodyStyle="text-align: right;"
                      footerStyle="text-align: right;"
              >
                <template #body="{data}">
                  {{ formatCurrency(data.com_agencia) }}
                </template>
                <template #footer>
                  {{ formatCurrency(total.com_agencia) }}
                </template>
              </Column>

              <Column header="% Distribuidor" :sortable="true" field="com_distribuidor"
                      bodyStyle="text-align: right;"
                      footerStyle="text-align: right;"
              >
                <template #body="{data}">
                  {{ formatCurrency(data.com_distribuidor) }}
                </template>
                <template #footer>
                  {{ formatCurrency(total.com_distribuidor) }}
                </template>
              </Column>

              <Column header="% Banca" :sortable="true" field="com_banca"
                      bodyStyle="text-align: right;"
                      footerStyle="text-align: right;"
              >
                <template #body="{data}">
                  {{ formatCurrency(data.com_banca) }}
                </template>
                <template #footer>
                  {{ formatCurrency(total.com_banca) }}
                </template>
              </Column>

              <Column header="Saldo" :sortable="true" field="saldo"
                      bodyStyle="text-align: right;"
                      footerStyle="text-align: right;"
              >
                <template #body="{data}">
                  <div :class="getNumberColour(data.saldo)">
                    {{ formatCurrency(data.saldo) }}
                  </div>

                </template>
                <template #footer>
                  <div :class="getNumberColour(total.saldo)">
                    {{ formatCurrency(total.saldo) }}
                  </div>
                </template>
              </Column>

              <Column header="Operador" :sortable="true" field="saldo_operador"
                      bodyStyle="text-align: right;"
                      footerStyle="text-align: right;">
                <template #body="{data}">
                  <div :class="getNumberColour(data.saldo_operador)">
                    {{ formatCurrency(data.saldo_operador) }}
                  </div>
                </template>
                <template #footer>
                  <div :class="getNumberColour(total.saldo_operador)">
                    {{ formatCurrency(total.saldo_operador) }}
                  </div>
                </template>
              </Column>

            </Row>
          </ColumnGroup>
        </DataTable>
      </div>
    </div>
  </div>
<Toast/>
</template>

<script>

import generalMixin from '@/mixins/generalMixin.js'
import Filter from "@/components/Filters/Filter";
import ReportService from "@/service/backend/ReportService";
import LabelLight from "@/components/misc/Labels/LabelLight";
import FilterAnalyst from "@/components/Filters/FilterAnalyst";
// import Label2 from "@/components/misc/Labels/Label2";


export default {
  mixins: [generalMixin],
  components: {
    'Filter': Filter,
    'FilterAnalyst': FilterAnalyst,
    "LabelLight": LabelLight,
  },
  data() {
    return {
      loadingTable: false,
      report: null,
      filters: null,
      dates: null,
      header: null,
      total: {
        venta: 0,
        premio: 0,
        com_agencia: 0,
        com_distribuidor: 0,
        com_banca: 0,
        saldo: 0,
        saldo_operador: 0,
      },
      identity: null,
    }
  },
  saleService: null,
  created() {
    this.reportService = new ReportService()
    this.$primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubrer", "Noviembre", "Diciembre"];
    this.$primevue.config.locale.dayNamesMin = ["Do", "Lu", "Ma", "Mi", "Ju", "Vr", "Sa"];
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  methods: {
    async getData(filters) {
      // Just in case only one date was selected
      this.header = this.getFields(filters.selectedGroupBy);
      filters.identity = this.identity;
      this.filters = filters;
      this.dates = filters.fechas;
      this.loadingTable = true;
      // Account for change of names
      filters.selectedGroupBy = filters.selectedGroupBy === 'bloque' ? 'comercializador' : filters.selectedGroupBy;
      this.report = await this.reportService.getCombinedReport(filters);
      if (this.report[0] && this.report[0].error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: this.report[0].error, life: 3000});
        this.report= null;
        this.resetTotals();
      } else {
        this.calculateTotal();
      }

      this.loadingTable = false
    },
    resetTotals() {
      this.total.venta = 0;
      this.total.premio = 0;
      this.total.com_agencia = 0;
      this.total.com_distribuidor = 0;
      this.total.com_banca = 0;
      this.total.saldo = 0;
      this.total.saldo_operador = 0;
    },
    calculateTotal() {
      this.resetTotals();
      this.report.forEach((item) => {
        this.total.venta += Number(item.venta);
        this.total.premio += Number(item.premio);
        this.total.com_agencia += Number(item.com_agencia);
        this.total.com_distribuidor += Number(item.com_distribuidor);
        this.total.com_banca += Number(item.com_banca);
        this.total.saldo += Number(item.saldo);
        this.total.saldo_operador += Number(item.saldo_operador);
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    getTitle() {
      const title = this.header && this.header.title ? this.header.title : null;
      return 'Reporte Combinado por ' + this.capitalizeFirstLetter(title);
    },
    getNumberColour(number) {
      return (number < 0) ? 'negative-number' : 'positive-number'
    },


  }

}
</script>
<style>
.selections {
  /*display: grid;*/
  /*grid-template: repeat(3, 1fr) / repeat(5, 1fr);*/
  /*grid-auto-flow: column dense;*/
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.columns {
  margin: 10px;
}

.negative-number {
  color: red;
}

.prize-number {
  color: orangered;
}
</style>