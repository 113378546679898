<template>

  <Dialog
      :style="{width: '33vw'}"
      v-model:visible="computedVisible"
      :modal="true"
      :closable="true">

    <div id="overlay" v-if="overlay"></div>

    <template #header>
      <h3>Agencia {{ agencyDetails && agencyDetails.tagencia }}</h3>
    </template>

    <TabView :activeIndex="activeIndex" scrollable>
      <!--      Tab 1-->
      <TabPanel>
        <template #header>
          <i class="pi pi-home icon"></i>
          <span class="tab-title">&nbsp;&nbsp;Detalles</span>
        </template>

        <Tab1
            :agency="agency"
            :agencyDetails="agencyDetails"
            @close="closeDialog"
        />
      </TabPanel>

      <!--        Tab 2-->
      <TabPanel>
        <template #header>
          <i class="pi pi-percentage icon"></i>
          <span class="tab-title">&nbsp;&nbsp;Porcentajes</span>
        </template>
        <Tab2
            :agency="agency"
            @close="closeDialog"
        />
      </TabPanel>

      <!--      Tab 3-->
      <TabPanel>
        <template #header>
          <i class="pi pi-directions icon"></i>
          <span class="tab-title">&nbsp;&nbsp;Instalación</span>
        </template>

        <Tab3 :agency="agency"/>

      </TabPanel>

    </TabView>


    <template #footer>
      <!--      <Button label="No" icon="pi pi-times" class="p-button-text"/>-->
      <!--      <Button label="Yes" icon="pi pi-check" autofocus/>-->
    </template>
  </Dialog>

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import Tab1 from "@/components/Settings/Agencies/Dialog/Tab1";
import Tab2 from "@/components/Settings/Agencies/Dialog/Tab2";
import Tab3 from "@/components/Settings/Agencies/Dialog/Tab3";

export default {
  name: "EditAgencyDialog",
  components: {
    'Tab1': Tab1,
    'Tab2': Tab2,
    'Tab3': Tab3,
  },
  props: {
    show: Boolean,
    agency: Object,
    agencyDetails: Object,
  },
  mixins: [generalMixin],
  data() {
    return {
      activeIndex: 0,
      overlay: false,
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    }
  },
  computed: {
    computedVisible: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close')
      }
    },
  }
}
</script>

<style scoped lang="scss">

.icon {
  //color: var(--green-banklot);
}
.tab-title {
  color: var(--green-banklot);
}
</style>
