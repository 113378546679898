<template>

  <Toast/>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>

  <Card style="border-radius:12px;">
    <template v-slot:title>
      Nivel de Acceso
    </template>
    <template v-slot:subtitle>
      Añadir usuario
    </template>
    <template #content>
      <div class="filters">
        <!--        Nivel de Acceso-->
        <Dropdown v-model="selectedRoleId"
                  :options="getUserRoles(null, identity.entityId)"
                  optionLabel="label"
                  optionValue="code"
                  optionGroupLabel="label" optionGroupChildren="items"
                  scroll-height="300px"
                  @onChange="onChangeRoleId"
                  placeholder="Seleccionar Nivel de Acceso"
                  class="filterItem" :loading="loadingComercializador"
                  v-if="Number(identity.roleId) <= 3"
                  style="width: 100%; margin-bottom: 20px;"/>

        <!--        Entidad -->
        <EntityFilter
            v-if="selectedRoleId > 1 && selectedRoleId !== 7"
            :selectedRoleId="Number(selectedRoleId)"
            :entity_id="selectedEntityId"
            @setEntityId="onChangeEntityId"
            title="Nombre de entidad"
        />
      </div>

    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-between">
        <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left"/>
        <Button label="Siguiente"
                @click="nextPage()"
                icon="pi pi-angle-right"
                iconPos="right"
                :disabled="getNextButtonStatus()"
        />
      </div>
    </template>
  </Card>

  <router-view v-slot="{Component}"
               @prevPage="prevPage($event)"
               @nextPage="nextPage($event)">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>

</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import {useVuelidate} from "@vuelidate/core";
import EditDropdown from "@/components/misc/Labels/EditDropdown";
import EntityFilter from "@/components/Settings/Users/Add/EntityFilter";
import UserService from "@/service/backend/UserService";

export default {
  name: "Step2",
  components: {EntityFilter, EditDropdown},
  mixins: [generalMixin],
  setup: () => ({v$: useVuelidate()}),
  created() {
    this.mainService = new MainService;
    this.userService = new UserService;

    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };

    this.theDate = this.getYesterdayString();
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";

  },
  mounted() {
    // this.comercializadores = this.refreshComercializador();
    this.bancas = this.refreshBanca();

    this.selectedComercializador = this.$store.getters.getUser.id_comercializador;
    this.selectedBanca = this.$store.getters.getUser.id_banca;

    // It's a block
    if (Number(this.identity.roleId) < 1) {
      this.selectedComercializador = this.identity.entityId;
    }
  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/usuarios/agregar-1'
        },
        {
          label: 'Nivel de Acceso',
          to: '/usuarios/agregar-2'
        },
      ],
      selectedComercializador: this.$store.getters.getProfile.id_comercializador,
      selectedBanca: null,
      selectedDistribuidor: null,

      comercializadores: null,
      bancas: null,
      distribuidores: null,

      loadingComercializador: false,
      loadingBanca: false,
      loadingDistribuidor: false,

      pageIndex: 0,
      roleId: null,
      params: null,

      selectedRoleId: null,
      selectedEntityId: null,
    }
  },
  methods: {
    async refreshComercializador() {
      this.loading = true;
      this.selectedComercializador = null;
      this.comercializadores = await this.mainService.getComercializador({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        page: 'users',
      });
      this.loading = false;
    },
    async refreshBanca() {
      this.loading = true;
      this.selectedBanca = null;
      this.bancas = await this.mainService.getBanca({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        page: 'users',
      });
      this.loading = false;
    },
    async refreshDistribuidor() {
      this.loadingDistribuidor = true;
      this.selectedDistribuidor = null;
      this.distribuidores = await this.mainService.getDistribuidor({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        conalot: this.conalot,
        page: 'users',

      });
      this.loadingDistribuidor = false;
    },
    nextPage() {
      this.saveUser();
    },
    prevPage() {
      this.$store.commit('setUser', {
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        id_distribuidor: this.selectedDistribuidor,
        name: this.$store.getters.getUser.name,
        email: this.$store.getters.getUser.email,
        password: this.$store.getters.getUser.password,
      });
      this.$router.push('/usuarios/agregar-1');
    },
    async onChangeRoleId(roleId) {
      this.selectedRoleId = roleId.value;
      this.selectedEntityId = null;
    },
    async onChangeEntityId(entityId) {
      this.selectedEntityId = entityId.value;
      // this.selectedEntityId = null;
    },
    async saveUser() {
      const user = {
        name: this.$store.getters.getUser.name,
        email: this.$store.getters.getUser.email,
        password: this.$store.getters.getUser.password,
        role_id: this.selectedRoleId,
        entity_id: this.selectedEntityId,
        status_id: 2,
      };
      try {
        // Encrypt password here
        user.password = user.password ? this.encodePassword(user.password) : user.password;

        if (user.id) {
          await this.userService.update(user);
          this.userDialog = false;
          this.$emit('close-reload');
        } else {
          const newUser = await this.userService.create(user);

          if (newUser && newUser.error) {
            this.$toast.add({
              severity: 'error',
              summary: '',
              detail: 'No se pudo crear/editar el usuario | ' + newUser.error,
              life: 10000
            });
          } else {
            this.$toast.add({severity: 'success', summary: '', detail: 'Usuario creado', life: 3000});
            this.$router.push('/usuarios');
          }
        }

      } catch (e) {
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'No se pudo crear/editar el usuario | ' + e.message,
          life: 10000
        });
      }
    },
    getNextButtonStatus() {
      let response = true;

      if (this.selectedRoleId === 1 || this.selectedRoleId === 7)  {
        response = false;
      } else {
        if (this.selectedEntityId !== null) {
          response = false;
        }
      }
      return response;
    }
  },

}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 20px 0 5px 0;
}

</style>