import * as bcrypt from 'bcryptjs';
import moment from "moment-timezone";

export default {
    data() {
        return {
            gameCategories: ''
        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format('DD/MM/YYYY');
        },
        formatDate2(value) {
            return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        formatDateAndtime(value) {
            const dDate = new Date(value)
            let options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};
            return this.capitalizeFirstLetter(dDate.toLocaleDateString('es-VE', options));
        },
        getTodayDate() {
            return moment().format('YYYY-MM-DD');
        },
        getYesterdayString() {
            return moment().subtract(1, 'day').format('YYYY-MM-DD')
        },
        formatDateLong2(value) {
            return this.capitalizeFirstLetter(moment(value).locale('es').format('dddd DD.MM.YYYY'));
        },
        formatDateLongMoment(value) {
            const dDate = new Date(value)
            let options = {year: 'numeric', month: 'numeric', day: 'numeric'};

            const dDay = moment(value).locale('es').format('dddd')

            return this.capitalizeFirstLetter(dDay) + ' ' + dDate.toLocaleDateString('es-VE', options);


            // const dDate = new Date(value)
            // let options = {year: 'numeric', month: 'numeric', day: 'string'};
            // return this.capitalizeFirstLetter(dDate.toLocaleDateString('es-VE', options));
        },
        formatTime(timeString) {
            const H = +timeString.substr(0, 2);
            const h = H % 12 || 12;
            const ampm = (H < 12 || H === 24) ? " AM" : " PM";
            timeString = h + timeString.substr(2, 3) + ampm;
            return timeString;
        },
        formatTime2(timeString) {
            return moment(timeString).format('hh:mm:ss A');
        },
        formatNumber(value) {
            return Number(value).toFixed().replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        formatNumberWithComma(value) {
            value = value.replace(',', '.');
            value = Number(value);
            return Number(value).toFixed().replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        formatCurrency(value) {
            value = (isNaN(value)) ? 0 : value;
            return Number(value).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        getColour(value) {
            try {
                let num = (Number(value) * 900 / this.max)
                num = Math.ceil(num / 100) * 100;
                if (num > 800) {
                    return "border-color: var(--primary-color); min-width:105px; color: white; background-color: var(--green-" + num + ")"
                }
                if (num > 300) {
                    return "border-color: var(--primary-color); min-width:105px; color: white; background-color: var(--green-" + num + ")"
                }
                return "border-color: var(--primary-color); min-width:105px; color: black; background-color: var(--green-" + num + ")"
            } catch (e) {
                console.log(e);
                return null;
            }
        },
        getCancelColour(value) {
            try {
                let num = (Number(value) * 900 / this.max)
                num = Math.ceil(num / 100) * 100;
                if (num > 800) {
                    return "min-width:105px; color: white; background-color: var(--red-" + num + ")"
                }
                if (num > 300) {
                    return "min-width:105px; color: white; background-color: var(--red-" + num + ")"
                }
                return "min-width:105px; color: black; background-color: var(--red-" + num + ")"
            } catch (e) {
                console.log(e);
                return null;
            }
        },
        getWinnerColour(value) {
            try {
                let num = (Number(value) * 900 / Number(this.max))
                num = Math.ceil(num / 100) * 100;
                if (num > 800) {
                    return "border-color: yellow; min-width:105px; color: white; background-color: var(--yellow-" + num + ")"
                }
                if (num > 300) {
                    return "border-color: yellow; min-width:105px; color: white; background-color: var(--yellow-" + num + ")"
                }
                return "border-color: yellow; min-width:105px; color: black; background-color: var(--yellow-" + num + ")"
            } catch (e) {
                console.log(e);
                return null;
            }
        },
        getTicketDialogColour(value) {
            try {
                let num = Math.ceil(Number(value) * 100);
                num = (num / 100) * 100;
                num = Math.ceil(num / 100) * 100;
                if (num > 800) {
                    return "min-width:105px; color: grey; background-color: var(--green-" + num + ")";
                }
                return "min-width:105px;color: white,background-color: var(--green-" + num + ")";
            } catch (e) {
                console.log(e);
                return null;
            }
        },
        getStatus(data) {
            return (data.expired && data.tstatus === 'GANADOR') ? 'NO PAGADO' : data.tstatus;
        },
        statusClass(data, override = null) {
            data = (override === null) ? data : override;
            return [{
                'anulado': data.tstatus === 'ANULADO',
                'ganador': data.tstatus === 'GANADOR' && !data.expired,
                'pagado': data.tstatus === 'PAGADO',
                'no-pagado': data.tstatus === 'GANADOR' && data.expired,
            }];
        },
        ticketPrizeClass(data) {
            return [{
                'winner': Number(data.mmonto_premio) > 0,
                'normal': Number(data.mmonto_premio) === 0,
            }];
        },

        getUniqueValuesByKey(thisObject, key) {
            return [...new Map(thisObject.map((item) => [item[key], item])).values()];
        },
        zeroPad(num, places) {
            return String(num).padStart(places, '0');
        },
        capitalizeFirstLetter(tString) {
            return tString ? tString.charAt(0).toUpperCase() + tString.slice(1) : '';
        },
        getSign(number) {
            const signs = {
                0: 'ARIES',
                1: 'TAURO',
                2: 'GEMINIS',
                3: 'CANCER',
                4: 'LEO',
                5: 'VIRGO',
                6: 'LIBRA',
                7: 'ESCORPIO',
                8: 'SAGITARIO',
                9: 'CAPRICORNIO',
                A: 'ACUARIO',
                B: 'PISCIS',
            };
            return signs[number]
        },
        getSignValue(value) {
            try {
                let response = value.nnumero_ganador;

                if (value.id_lista === 2 || value.id_lista === 3 && value.nnumero_ganador !== null) {
                    const sign = (this.getSign(value.nnumero_ganador.slice(value.nnumero_ganador.length - 1))).substring(0, 3);
                    const number = (value.id_lista === 3) ? value.nnumero_ganador.substring(0, 3) : value.nnumero_ganador.substring(0, 2);
                    response = number + '-' + sign;
                }
                return response;
            } catch (e) {
                console.log(e)
                return {error: e.message};
            }
        },
        getWeekDay(number) {
            const days = {
                0: 'Domingo',
                1: 'Lunes',
                2: 'Martes',
                3: 'Miércoles',
                4: 'Jueves',
                5: 'Viernes',
                6: 'Sábado',
            };
            return days[number];
        },
        getMonth(number) {
            const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
            return monthNames[number]
        },
        async refreshVuexData(date) {
            // // Get customer data and store it in memory
            const headerData = await this.mainService.getDailyOptions({fecha: date});
            this.$store.commit('setComercializadores', headerData.comercializador);
            this.$store.commit('setDistribuidores', headerData.distribuidor);
            this.$store.commit('setBancas', headerData.banca);
            this.$store.commit('setAgencias', headerData.agencia);
            this.$store.commit('setSorteos', headerData.sorteo);
            this.$store.commit('setSorteosAnimalitos', headerData.sorteoAnimalitos);
            this.$store.commit('setListas', headerData.lista);
            this.$store.commit('setTipos', headerData.tipo);
        },
        getComercializador(id) {
            const res = this.$store.getters.getComercializadores;
            return res && res[id]
        },
        getBanca(id) {
            const res = this.$store.getters.getBancas;
            return res && res[id]
        },
        getDistribuidor(id) {
            const res = this.$store.getters.getDistribuidores;
            return res && res[id]
        },
        getAgencia(id) {
            const res = this.$store.getters.getAgencias;
            return res && res[id]
        },
        getStatusLabel(id) {
            const res = ['VENDIDO', 'ANULADO', 'GANADOR', 'REVERSADO FUERA DE TIEMPO', 'PAGADO'];
            return res[id]
        },
        calculateTotal(data, field) {
            let total = 0;
            if (data) {
                for (let item of data) {
                    total += Number(item[field])
                }
            }
            return total;
        },
        getProductColour(number) {
            const colours = {
                0: 'blue',
                1: 'teal',
                2: 'green',
                3: 'cyan',
                4: 'purple',
                5: 'pink',
                6: 'red',
                7: 'orange',
                8: 'yellow',
            };

            return colours[number];
        },
        getCategory(number) {
            const categories = {
                all: 'Todas',
                lottery: 'Lotería',
                animal: 'Animalitos',
            };

            return categories[number];
        },
        selectedCursor() {
            return {
                selectedCursor: `url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E %3Cpath d='M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4 L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1 c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1 c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z'/%3E %3C/svg%3E"), pointer`,
            }
        },
        getFields(field) {
            let title = field;

            if (field === 'tcomercializador')
                title = 'bloque';

            if (field === 'perfil_pago_premios')
                title = 'perfil de pago';

            return {
                title: this.capitalizeFirstLetter(title),
                field: `t${field}`
            };
        },
        getTitle() {
            return this.header ? this.header : null;
        },
        validateEmail(email) {
            const result = String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            return !!result;
        },
        validateNonEmpty(daString) {
            let response = false;
            if (typeof daString === 'string') {
                if (daString.length > 0)
                    response = true;
            } else if (typeof daString === 'number') {
                response = true;
            } else {
                response = daString !== undefined
            }
            return {value: daString, result: response}
        },
        getUserRoles(number, entityId) {
            const rolesArr = {
                1: 'Administrador',
                2: 'Bloque',
                3: 'Banca',
                4: 'Distribuidor',
                // 5: 'Agencia',
                // 6: 'Lotería',
                7: 'Analista',
            };
            const thisRole = this.$store.getters.getProfile.role_id;
            let allowedRoles = [];
            const roles = [
                {
                    label: 'Entidad',
                    items: [
                        {label: 'Bloque', code: 2},
                        {label: 'Banca', code: 3},
                        {label: 'Distribuidor', code: 4},
                    ]
                },
                {
                    label: 'Uso Interno',
                    items: [
                        {label: 'Administrador', code: 1},
                        {label: 'Analista', code: 7}
                    ]
                }
            ];

            if (entityId !== null) {
                roles[0]['items'].forEach((item) => {
                    if (item.code > thisRole && item.code < 6)
                        allowedRoles.push(item)
                });
                allowedRoles = [{
                    label: 'Entidad',
                    items: allowedRoles
                }]
            } else {
                allowedRoles = roles;
            }

            return number ? rolesArr[number] : allowedRoles;
        },
        getConalotUserRoles(number) {
            const rolesArr = {
                1: 'Administrador',
                6: 'Lotería',
            };
            // const thisRole = this.$store.getters.getProfile.role_id;
            // let allowedRoles = [];
            const allowedRoles = [{
                label: 'Entidad',
                items: [
                    {label: 'Administrador', code: 1},
                    {label: 'Loteria', code: 6}
                ]
            }];
            return number ? rolesArr[number] : allowedRoles;
        },
        getUserStatus(number) {
            const statusArr = {
                1: 'Pendiente',
                2: 'Activo',
            };
            const status = [
                {label: 'Activo', code: 2},
                {label: 'Pendiente', code: 1},
            ];
            return number ? statusArr[number] : status;
        },
        getSeverity(status) {
            return (status === 2) ? 'success' : 'danger';
        },
        encodePassword(password) {
            const salt = bcrypt.genSaltSync(10);

            return bcrypt.hashSync(password, salt);
        },
        getIdentity() {
            return {
                roleId: this.$store.getters.getProfile.role_id,
                entityId: this.$store.getters.getProfile.entity_id,
            };
        },
        getEntityStatus(number) {
            const statusArr = {
                0: 'Inactivo',
                1: 'Activo',
                2: 'Bloqueo Parcial',
                3: 'Bloqueo Total',
                4: 'Borrado',
            }
            const status = [
                {label: 'Inactivo', code: 0},
                {label: 'Activo', code: 1},
                {label: 'Bloqueo Parcial', code: 2},
                {label: 'Bloqueo Total', code: 3},
                {label: 'Borrado', code: 4},
            ];
            if (number === 0) {
                return 'Inactivo';
            }
            return number ? statusArr[number] : status;
        },
        getEntitySeverity(status) {
            let response = null;
            switch (status) {
                case 1:
                    response = 'success';
                    break;
                case 4:
                    response = 'info';
                    break;
                case 3:
                    response = 'danger';
                    break;
                case 2:
                    response = 'warning';
                    break;
                case 0:
                    response = 'info';
                    break;
            }
            return response;
        },

        getEntityStatusClass(status) {
            let response = null;
            switch (status) {
                case 1:
                    response = 'success';
                    break;
                case 4:
                    response = 'info';
                    break;
                case 3:
                    response = 'danger';
                    break;
                case 2:
                    response = 'warning';
                    break;
                case 0:
                    response = 'info';
                    break;
            }
            return response;
        },

        getUserStatusClass(status) {
            let response = null;
            switch (status) {
                case 2:
                    response = 'success';
                    break;
                case 1:
                    response = 'danger';
                    break;
            }
            return response;
        },

        getLockIcon(locked) {
            locked = Number(locked);
            return (locked === true || locked === 2 || locked === 3) ? 'pi pi-lock' : 'pi pi-unlock';
        },
        wait(ms) {
            const start = new Date().getTime();
            let end = start;
            while (end < start + ms) {
                end = new Date().getTime();
            }
        },
        convertDate(sDate) {
            const day = ('0' + sDate.getDate()).slice(-2);
            const month = ('0' + (sDate.getMonth() + 1)).slice(-2);
            const year = sDate.getFullYear();

            return `${year}-${month}-${day}`;
        },
    },

}