<template>
      <div class="card" v-if="identity.roleId!==7">
        <div class="flex justify-content-center" v-if="loadingRecent">
          <ProgressSpinner class="spinner"/>
        </div>
        <div v-if="!loadingRecent">
          <h5>Ventas Recientes</h5>
          <DataTable :value="recentSales" :rows="7" :paginator="true" responsiveLayout="scroll"
                     class="p-datatable-sm">
            <Column field="fecha" header="Fecha" :sortable="true" :headerStyle="legendHeaderStyle" style="width:25%"
                    bodyStyle="text-align: left">
              <template #body="{data}">
                {{ formatDateLong2(data.fecha) }}
              </template>
            </Column>
            <Column field="venta" header="Venta" :sortable="true" :headerStyle="legendHeaderStyle" style="width:15%"
                    bodyStyle="text-align: right">
              <template #body="{data}">
                {{ formatCurrency(data.venta) }}
              </template>
            </Column>
            <Column field="premio" header="Premio" :sortable="true" :headerStyle="legendHeaderStyle" style="width:15%"
                    bodyStyle="text-align: right">
              <template #body="{data}">
                {{ formatCurrency(data.premio) }}
              </template>
            </Column>
            <Column field="premio" header="% Premiación" :sortable="true" :headerStyle="legendHeaderStyle"
                    style="width:20%"
                    bodyStyle="text-align: right">
              <template #body="{data}">
                {{ ((data.premio / data.venta) * 100).toFixed(2) }}%
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import SaleService from "@/service/backend/SaleService";

export default {
  name: "RecentSales",
  mixins: [generalMixin],
  created() {
    this.saleService = new SaleService();
    this.identity = this.getIdentity();
  },
  data() {
    return {
      identity: null,
      loadingRecent: false,
      recentSales: null,
      legendHeaderStyle: 'background-color: var(--surface-a); color: var(--surface-900)',

    }
  },
  async mounted() {
    this.loadingRecent = true;
    this.recentSales = await this.saleService.getRecentSales({identity: this.identity});


    this.loadingRecent = false;
  }

}
</script>

<style scoped>

</style>