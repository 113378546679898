<template>

  <Toast/>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>

  <Card style="border-radius:12px;">
    <template v-slot:title>
      Nombre
    </template>
    <template v-slot:subtitle>
      Añadir agencia
    </template>
    <template #content>
      <div class="filters">

        <!--          Name-->
        <div class="field">
          <div class="p-float-label">
            <InputText id=name
                       placeholder="Nombre de punto de venta"
                       v-model="agencyName"
                       autocomplete="off"
                       style="width:100%;"/>
          </div>
        </div>
      </div>


    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-end">
        <!--          <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" />-->
        <Button label="Siguiente"
                @click="nextPage()"
                icon="pi pi-angle-right"
                iconPos="right"
                :disabled="this.agencyName ? disabled : ''"
        />
      </div>
    </template>
  </Card>

  <router-view v-slot="{Component}"
               @prevPage="prevPage($event)"
               @nextPage="nextPage($event)">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>

</template>

<script>



export default {
  name: "Step1",
  created() {
    this.agencyName = this.$store.getters.getAgency.agencyName;
  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/agencias/agregar-1'
        },
        {
          label: 'Cadena de comercialización',
          to: '/agencias/agregar-2'
        },
        {
          label: 'Dirección',
          to: '/agencias/agregar-3'
        },

      ],
      agencyName: null,
      pageIndex: 0,
      roleId: null,
    }
  },

  methods: {
    nextPage() {
      this.$store.commit('setAgency', {
        id_comercializador: this.$store.getters.getAgency.id_comercializador,
        id_banca: this.$store.getters.getAgency.id_banca,
        id_distribuidor: this.$store.getters.getAgency.id_distribuidor,
        agencyName: this.agencyName,
        id_estado: this.$store.getters.getAgency.id_estado,
        id_municipio: this.$store.getters.getAgency.id_municipio,
      });
      this.$router.push({name: 'agencias/agregar-2',})
    },

  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 2px 0 5px 0;
}

</style>