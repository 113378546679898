import BaseService from "./BaseService";

class MainService extends BaseService {

    async getDailyOptions(req) {
        const response = await this.axios.post('/main/options', req);
        return response.data;
    }

    async getSorteos(req) {
        const response = await this.axios.post('/main/sorteos', req);
        return response.data;
    }

    async getListas() {
        const response = await this.axios.post('/main/listas');
        return response.data;
    }

    async getTipos() {
        const response = await this.axios.post('/main/tipos',);
        return response.data;
    }

    async getPrestadoresServicio(req) {
        const response = await this.axios.post('/main/prestadores_servicio', req);
        return response.data;
    }

    async getComercializador(req) {
        const response = await this.axios.post('/main/comercializador', req);
        return response.data;
    }

    async getBanca(req) {
        const response = await this.axios.post('/main/banca', req);
        return response.data;
    }

    async getDistribuidor(req) {
        const response = await this.axios.post('/main/distribuidor', req);
        return response.data;
    }

    async getAgencia(req) {
        const response = await this.axios.post('/main/agencia', req);
        return response.data;
    }

    async getLoteria(req) {
        const response = await this.axios.post('/main/loteria', req);
        return response.data;
    }

    async getProducto(req) {
        const response = await this.axios.post('/main/producto', req);
        return response.data;
    }

    async getModalidad(req) {
        const response = await this.axios.post('/main/modalidad', req);
        return response.data;
    }


    async getFilterOptions(req) {
        const response = await this.axios.post('/main/filtros', req);
        return response.data;
    }

    async getClientsIp() {
        const response = await this.axios.get('https://api.country.is/');
        return response.data;
    }

    async getPremios(req) {
        const response = await this.axios.post('/main/premios', req);
        return response.data;
    }

    async getEstados() {
        const response = await this.axios.get('/main/estados');
        return response.data;
    }

    async getMunicipios(req) {
        const response = await this.axios.post('/main/municipios', req);
        return response.data;
    }

    async getConalotEntities(req) {
        const response = await this.axios.post('/main/conalot', req);
        return response.data;
    }

    async setConalotEntities(req) {
        const response = await this.axios.post('/main/conalot/set', req);
        return response.data;
    }

    async getDateAndTime() {
        const response = await this.axios.get('/main/date_and_time');
        return response.data;
    }

}

export default MainService;
